import { db, getCurrentFirebaseUser, storage, firestoreTimestamp } from '~/plugins/firebase'
import { collection, doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'
import { FormClient } from '~/usecase/client/clientUseCase'


export interface IClientEntity {
  name: string
  kana?: string
  photoPath?: string
  companyName?: string
  companyId: string
  staffId: string
  categoryId: string
  stateId: string
  address?: string
  latitude?: number
  longitude?: number
  emails?: string[]
  phone?: string
  birthday?: string
  age?: string
  social?: { [name: string]: string }
  note?: string
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface AppClient extends IClientEntity {
  id: string
}

export class ClientEntity {
  static readonly CLIENTS = 'clients'
  static readonly COMPANIES = 'companies'

  private getRef(
    companyId: string,
    clientId: string
  ): firebase.firestore.DocumentReference {
    const companyRef = db.collection(ClientEntity.COMPANIES).doc(companyId)
    return companyRef.collection(ClientEntity.CLIENTS).doc(clientId)
  }

  private getCollection(
    companyId: string
  ): firebase.firestore.CollectionReference {
    const companyRef = db.collection(ClientEntity.COMPANIES).doc(companyId)
    return companyRef.collection(ClientEntity.CLIENTS)
  }

  getClient$(companyId: string, clientId: string): Observable<AppClient> {
    const doc$ = doc(this.getRef(companyId, clientId))

    return doc$.pipe(
      map((snapshot) => {
        const client = snapshot.data() as IClientEntity
        const appClient = { ...client, id: snapshot.id }
        return appClient
      })
    )
  }

  getClient(companyId: string, clientId: string): Promise<AppClient> {
    return firstValueFrom(this.getClient$(companyId, clientId))
  }

  getAllClient$(companyId: string): Observable<AppClient[]> {
    const collectionSnapshot$ = collection(this.getCollection(companyId))
    return collectionSnapshot$.pipe(
      map((allSnapshot) => {
        const allClients: AppClient[] = []
        allSnapshot.forEach((snapshot) => {
          const client: IClientEntity = snapshot.data() as IClientEntity
          const appClient: AppClient = {
            ...client,
            id: snapshot.id
          }
          allClients.push(appClient)
        })
        return allClients
      })
    )
  }

  // collectionのRefにaddするだけ
  async addClient(companyId: string, data: FormClient) {
    const saveData: IClientEntity = {
      ...data,
      companyId,
    }
    await this.getCollection(companyId).add(saveData)
  }

  // referenceに対してupdateだけで良い
  async updateClient(
    companyId: string,
    clientId: string,
    data: Partial<IClientEntity> // Partial: updateは、変更部分のプロパティのみで良いため
  ) {
    data.updatedAt = firestoreTimestamp()
    await this.getRef(companyId, clientId).update(data)
  }

  async deleteClient(
    companyId: string,
    clientId: string,
  ) {
    await this.getRef(companyId, clientId).delete()
  }
}
