export enum PeriodId {
  Safe = 'safe',
  Warning = 'warning',
  Dangerous = 'dangerous'
}

export interface AppPeriod {
  id: PeriodId
  title: string
  days: number
  intervalText: string
  editable: boolean
}

export interface FormPeriod extends Pick<AppPeriod, 'days'> { }