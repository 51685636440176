// 型付け参考 https://typescript.nuxtjs.org/ja/cookbook/store#vanilla

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { AppClient } from '~/entities/clients/clientEntity'
import { RootState } from '~/store/index'

export interface ClientState {
  allClient: AppClient[] | null
  currentClientId: string | null
}

export const state: any = () => ({
  allClient: null,
  currentClientId: null
})

export const mutations: MutationTree<ClientState> = {
  setAllClient(state, data: AppClient[]) {
    state.allClient = data
  },
  setCurrentClientId(state, data: string) {
    state.currentClientId = data
  },
}

export const actions: ActionTree<ClientState, RootState> = {
  setAllClient(ctx, data: AppClient[]) {
    ctx.commit('setAllClient', data)
  },
  setCurrentClientId(ctx, data: string) {
    ctx.commit('setCurrentClientId', data)
  },
}

export const getters: GetterTree<ClientState, RootState> = {
  allClient(state: ClientState) {
    return state.allClient
  },
  // middleware/clientIdSetterで更新している
  currentClientId(state: ClientState) {
    return state.currentClientId
  },
  client(state: ClientState): AppClient | null {
    if (state.allClient && state.currentClientId) {
      // clientIdをもとに、get Client
      const client = state.allClient.find(
        client => client.id === state.currentClientId
      )
      if (!client) {
        console.error(`Client#${state.currentClientId} is not found in store.allClient.`)
        return null
      }
      return client
    } else {
      return null
    }
  },
}
