import { ActionTree, GetterTree, MutationTree } from "vuex/types/index"
import { AppUser } from "~/entities/users/userEntity"
import { RootState } from ".."

export interface AuthState {
  userInfo: AppUser | null
}

export const state: any = () => ({
  userInfo: null,
})

export const mutations: MutationTree<AuthState> = {
  setUserInfo(state, data: AppUser) {
    state.userInfo = data
  }
}

export const actions: ActionTree<AuthState, RootState> = {
  setUserInfo(ctx, data: AppUser) {
    ctx.commit('setUserInfo', data)
  }
}

export const getters: GetterTree<AuthState, RootState> = {
  userInfo(state) {
    return state.userInfo
  }
}
