import firebase from 'firebase/app'
import { auth } from '~/plugins/firebase'
import { Context, Middleware } from '@nuxt/types'


// Auth Subscription
let unsubscribeAuthChange: firebase.Unsubscribe | null = null

function authUrls(ctx: Context) {
  return [ctx.localePath('/'), ctx.localePath('/staffJoin/')]
}

const redirectToAuthPage = (ctx: Context) => {
  if (!ctx.route.name) throw new Error('context.route.nameが設定されていません')

  // 現在いるページにリダイレクトするとエラーが起きるため、条件分岐する
  // Unauthページにいる場合のみリダイレクト
  if (!authUrls(ctx).includes(ctx.route.path)) {
    console.log('redirect to Authorize Page')
    ctx.redirect(ctx.localePath('/'))
  }
}

const redirectToUnauthPage = (ctx: Context) => {
  if (!ctx.route.name) throw new Error('context.route.nameが設定されていません')

  // 現在いるページにリダイレクトするとエラーが起きるため、条件分岐する
  // Authページにいる場合のみリダイレクト
  if (authUrls(ctx).includes(ctx.route.path)) {
    console.log('redirect to Unauthorize Page')
    ctx.redirect(ctx.localePath('/clients/map/'))
  }
}

/**
 * 以前 Auth -> Company詳細
 * 
 * 今回: Auth -> ログイン済み、Company未定 -> Company詳細
 */

const middleware: Middleware = (ctx) => {
  unsubscribeAuthChange?.()
  unsubscribeAuthChange = null

  unsubscribeAuthChange = auth.onAuthStateChanged(user => {
    if (!user) {
      redirectToAuthPage(ctx)
      return
    } else {
      redirectToUnauthPage(ctx)
    }
  })
}
export default middleware
