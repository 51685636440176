
import { Component, Prop, Vue } from 'vue-property-decorator'
import UploadImages from 'vue-upload-drop-images'

/**
 * @emits input:path 選択されたphotoのPathを返す。型: string | null
 * @emits input:file 選択されたphotoFileを返す。型: File | null
 */
@Component({
  components: { UploadImages },
})
export default class SingleImageUploaderComponent extends Vue {
  reader = new FileReader()

  created() {
    this.initFileReader()
  }

  initFileReader() {
    this.reader.addEventListener(
      'load',
      () => {
        /**
         * ファイル読み込み
         * https://developer.mozilla.org/ja/docs/Web/API/FileReader/readAsDataURL#example
         */
        const selectedFilePath = this.reader.result
        this.$emit('input:path', selectedFilePath)
      },
      false
    )
  }

  onFileSelected(files: File[]) {
    const selectedFile = files[0]
    
    if (selectedFile) {
      // ファイル選択時
      this.$emit('input:file', selectedFile)
      this.reader.readAsDataURL(selectedFile)
    } else {
      // ファイル選択解除時
      this.$emit('input:file', null)
      this.$emit('input:path', null)
    }
  }
}
