import { collection } from 'rxfire/firestore'
import firebase from 'firebase/app'
import { db, firestoreTimestamp } from '~/plugins/firebase'
import { map, Observable } from 'rxjs'
import { queryFn } from '~/entities'
import { CompanyEntity } from '../companyEntity'
import { IStaffEntity } from '~/entities/companies/staff/staffEntity'

export interface ICategoryEntity {
  companyId: string
  name: string
  note?: string
  deleted: boolean
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}
export interface AppCategory extends ICategoryEntity {
  id: string
}
export interface FormCategory extends Pick<AppCategory, 'name' | 'note'> { }

const deleteStaffMsg = '既に招待を送っています。'

export class CategoryEntity {
  static readonly CATEGORIES = 'categories'
  static readonly COMPANEIS = 'companies'
  constructor() { }

  getDocumentRef(
    companyId: string,
    categoryId: string
  ): firebase.firestore.DocumentReference {
    return this.getCollection(companyId).doc(categoryId)
  }

  getCollection(companyId: string): firebase.firestore.CollectionReference {
    const companyRef = db.collection(CategoryEntity.COMPANEIS).doc(companyId)
    return companyRef.collection(CategoryEntity.CATEGORIES)
  }

  getQuery(companyId: string, queryFn?: queryFn): firebase.firestore.Query {
    const collection = this.getCollection(companyId)
    return queryFn ? queryFn(collection) : collection
  }

  getAllCategory$(
    companyId: string,
    queryFn?: queryFn
  ): Observable<AppCategory[]> {
    return collection(this.getQuery(companyId, queryFn))
      .pipe(
        map((allSnapshot) => {
          return allSnapshot.map((snapshot) => {
            const entity = snapshot.data() as ICategoryEntity

            // Entity -> AppModel 変換
            return {
              ...entity,
              id: snapshot.id,
            }
          })
        })
      )
  }

  getAllUndeletedCategory$(companyId: string) {
    return this.getAllCategory$(
      companyId,
      query => query.where('deleted', '==', false)
    )
  }

  async addCategory(companyId: string, data: ICategoryEntity) {
    await this.getCollection(companyId).add(data)
  }

  addCategoryByBatch(companyId: string, data: ICategoryEntity, batch: firebase.firestore.WriteBatch): firebase.firestore.WriteBatch {
    const categoryId = this.getCollection(companyId).doc().id
    return batch.set(
      this.getDocumentRef(companyId, categoryId), data
    )
  }

  async updateCategory(
    companyId: string,
    categoryId: string,
    data: Partial<ICategoryEntity>
  ) {
    data.updatedAt = firestoreTimestamp()
    await this.getDocumentRef(companyId, categoryId).update(data)
  }

  async deleteCategory(
    companyId: string,
    categoryId: string,
  ) {
    const data: Pick<ICategoryEntity, 'deleted'> = {
      deleted: true
    }
    await this.updateCategory(companyId, categoryId, data)
  }
}
