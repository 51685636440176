import { db } from '~/plugins/firebase'
import { collection, doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'

export interface IActionEntity {
  clientId: string
  allHandoutId: string[]
  title: string
  dateTimes: firebase.firestore.Timestamp
  content: string
  photoPaths?: string[]
  salesAmount?: number
  staffId: string
  companyId: string
  updatedAt: firebase.firestore.Timestamp
  createdAt: firebase.firestore.Timestamp
}

export interface AppAction extends IActionEntity {
  id: string
}


export class ActionEntity {
  static readonly COMPANIES = 'companies'
  static readonly ACTIONS = 'actions'
  static readonly CLIENTS = 'clients'
  static readonly PHOTO_PATHS = 'photoPaths'

  private getRef(
    companyId: string,
    actionId: string
  ): firebase.firestore.DocumentReference {
    const actionRef = this.getCollection(companyId).doc(actionId)
    return actionRef
  }

  private getCollection(
    companyId: string
  ): firebase.firestore.CollectionReference {
    const actionCollectionRef = db
      .collection(ActionEntity.COMPANIES)
      .doc(companyId)
      .collection(ActionEntity.ACTIONS)
    return actionCollectionRef
  }

  getAction$(companyId: string, actionId: string): Observable<AppAction> {
    const doc$ = doc(this.getRef(companyId, actionId))
    return doc$.pipe(
      map((snapshot) => {
        const action = snapshot.data() as IActionEntity
        const appAction = { ...action, id: snapshot.id }
        return appAction
      })
    )
  }

  getAction(companyId: string, actionId: string): Promise<AppAction> {
    return firstValueFrom(this.getAction$(companyId, actionId))
  }

  getAllCompanyAction$(companyId: string): Observable<AppAction[]> {
    //return snapshot
    const allActionSnapshot$ = collection(this.getCollection(companyId))
    //observable  status  de return
    return allActionSnapshot$.pipe(
      map((allActionSnapshot) => {
        const allAction: AppAction[] = []
        allActionSnapshot.forEach((actionSnapshot) => {
          const actionEntity = actionSnapshot.data() as IActionEntity
          const action = {
            ...actionEntity,
            id: actionSnapshot.id,
          }
          allAction.push(action)
        })
        return allAction
      })
    )
  }

  getAllClientAction$(
    companyId: string,
    clientId: string
  ): Observable<AppAction[]> {
    const allClientAction: AppAction[] = []
    const clientAllActionSnapshot$ = collection(
      db
        .collection(ActionEntity.COMPANIES)
        .doc(companyId)
        .collection(ActionEntity.ACTIONS)
        .where('clientId', '==', clientId)
    )

    return clientAllActionSnapshot$.pipe(
      map((allActionSnapshot) => {
        const allClientAction: AppAction[] = []
        allActionSnapshot.map((actionSnapshot) => {
          const action = actionSnapshot.data() as IActionEntity
          const appAction = {
            ...action,
            id: actionSnapshot.id,
          }
          allClientAction.push(appAction)
        })
        return allClientAction
      })
    )
  }

  getAllClientAction(companyId: string, clientId: string): Promise<AppAction[]> {
    return firstValueFrom(
      this.getAllClientAction$(companyId, clientId)
    )
  }

  async updateAction(
    companyId: string,
    actionId: string,
    data: Partial<IActionEntity> & Pick<IActionEntity, 'updatedAt'>
  ) {
    await this.getRef(companyId, actionId)
      .update(data)
      .then((_) => {
        console.log('success updateAction')
      })
  }

  async addAction(companyId: string, data: IActionEntity) {
    await this.getCollection(companyId)
      .add(data)
      .then((_) => {
        console.log('Success addAction')
      })
  }

  async addActionPhoto(companyId: string, actionId: string, photoPath: string): Promise<void> {
    const saveData = {
      photoPath: photoPath
    }
    const actionData = this.getRef(companyId, actionId)
    await actionData.set(saveData).then((_) => {
      console.log('Success save actionPhotopath')
    })
  }

  deleteAction(companyId: string, actionId: string) {
    return this.getRef(companyId, actionId).delete()
  }
}
