
import { Observable } from 'rxjs'
import {
  CategoryEntity,
  ICategoryEntity,
  AppCategory,
} from '~/entities/companies/categories/categoryEntity'


/**
 * /admin/manageページ配下のCRUD用のコンポーネント
 *    - index
 *    - new
 *    - edit
 *
 * で使うサーバー側のロジックのみを書く
 * (Entity, Storage, Authentication等)
 */
export class CategoryUseCase {
  static readonly COMPANIES = 'companies'

  protected readonly categoryEntity: CategoryEntity

  constructor(categoryEntity: CategoryEntity) {
    this.categoryEntity = categoryEntity
  }

  async addCategory(companyId: string, data: ICategoryEntity) {
    return await this.categoryEntity.addCategory(companyId, data)
  }

  getAllCategory$(companyId: string): Observable<AppCategory[]> {
    return this.categoryEntity.getAllCategory$(companyId)
  }

  getAllUndeletedCategory$(companyId: string): Observable<AppCategory[]> {
    return this.categoryEntity.getAllUndeletedCategory$(companyId)
  }

  async getCategoryName(companyId: string, categoryId: string): Promise<string> {
    const categoryDoc = await this.categoryEntity
      .getDocumentRef(companyId, categoryId)
      .get()
    const category = categoryDoc.data() as ICategoryEntity
    return category.name
  }

  updateCategory(
    companyId: string, categoryId: string,
    data: Partial<ICategoryEntity>) {
    return this.categoryEntity.updateCategory(companyId, categoryId, data)
  }

  deleteCategory(
    companyId: string,
    categoryId: string,
  ) {
    return this.categoryEntity.deleteCategory(companyId, categoryId)
  }
}
