
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SingleImageUploader from '~/components/utility/form/singleImageUploader.vue'
import ProgressCircular from '~/components/utility/progressCircular.vue'
import { AppCompany } from '~/entities/companies/companyEntity'
import { AppStaff } from '~/entities/companies/staff/staffEntity'
import {
  FormFeedback,
  IFeedbackEntity,
} from '~/entities/feedbacks/feedbackEntity'
import { AppUser } from '~/entities/users/userEntity'
import { firestoreTimestamp } from '~/plugins/firebase'

/**
 * Createのみ
 */
@Component({
  components: { SingleImageUploader, ProgressCircular },
  computed: {},
  i18n: {
    messages: {
      ja: {
        feedbackTitle: 'ご意見・ご要望を作成',
        cancel: 'キャンセル',
        save: '送信',
        title: 'タイトル',
        content: '内容',
      },
      en: {
        feedbackTitle: 'Feedback Title',
        cancel: 'Cancel',
        save: 'Send',
        title: 'Title',
        content: 'Content',
      },
    },
  },
})
export default class FeedbackFormComponent extends Vue {
  @Prop({ required: true })
  userInfo!: AppUser

  @Prop({ required: true })
  staffInfo!: AppStaff

  @Prop({ required: true })
  company!: AppCompany

  formData: FormFeedback = this.initFormData()
  selectedPhoto: File | null = null
  isSaving: boolean = false
  $refs!: {
    form: any
  }

  initFormData(): FormFeedback {
    return {
      title: '',
      content: '',
      photoPath: '',
    }
  }

  onSubmit() {
    if (!this.validate()) return

    this.isSaving = true
    return this.addFeedback()
      .then(() => {
        // 成功後
        this.isSaving = false
        this.$emit('afterSave')
      })
      .catch(this.handleFormError)
  }

  validate(): boolean {
    return this.$refs.form.validate()
  }

  handleFormError(error: Error) {
    alert(error.message)
  }

  async addFeedback() {
    if (this.selectedPhoto) {
      const photoPath = await this.$deps.feedbackUseCase.uploadFeedbackPhoto(
        this.selectedPhoto
      )
      this.formData.photoPath = photoPath
    }

    const entity: IFeedbackEntity = {
      ...this.formData,
      createdAt: firestoreTimestamp(),
      updatedAt: firestoreTimestamp(),
      userId: this.userInfo.id,
      companyId: this.company.id,
      staffId: this.staffInfo.id,
      replyMail: false,
    }
    return this.$deps.feedbackUseCase.addFeedback(entity)
  }
}
