
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { AppStaff, RoleType } from '~/entities/companies/staff/staffEntity'
import { auth } from '~/plugins/firebase'
import firebase from 'firebase/app'
import TopNav from '~/components/view/nav/topNav.vue'
import CompanyLogoPhoto from '~/components/view/layouts/companyLogoPhoto.vue'
import { AppUser } from '~/entities/users/userEntity'
import { AppCompany } from '~/entities/companies/companyEntity'
import { Subscription } from 'rxjs'

let unsubscribeAuthChange: firebase.Unsubscribe | null = null
const authSubscriptions: Record<string, Subscription | null> = {
  appUser: null,
}
const companySubscriptions: Record<string, Subscription | null> = {
  appStaff: null,
  company: null,
  allStaff: null,
  allState: null,
  allCategory: null,
  allCompanyAction: null,
  allClient: null,
  allHandout: null,
}

function clearAuthSubscriptions() {
  Object.keys(authSubscriptions).forEach((key) => {
    authSubscriptions[key]?.unsubscribe()
    authSubscriptions[key] = null
  })
  console.log('clear AuthSubscriptions')
}

function clearStoreAuthData(ctx: Vue) {
  ctx.$store.commit('auth/setUserInfo', null)
  console.log('clear StoreAuthData')
}

function clearCompanySubscriptions() {
  Object.keys(companySubscriptions).forEach((key) => {
    companySubscriptions[key]?.unsubscribe()
    companySubscriptions[key] = null
  })
  console.log('clear CompanySubscriptions')
}

function clearStoreCompanyData(ctx: Vue) {
  ctx.$store.commit('company/setCompany', null)
  ctx.$store.commit('company/setStaffInfo', null)
  ctx.$store.commit('company/setAllPeriod', null)
  ctx.$store.commit('company/setAllStaff', null)
  ctx.$store.commit('company/setAllCategory', null)
  ctx.$store.commit('company/setAllState', null)
  ctx.$store.commit('company/setAllHandout', null)
  ctx.$store.commit('client/setAllClient', null)
  ctx.$store.commit('action/setAllCompanyAction', null)
  console.log('clear StoreCompanyData')
}

function authUrls(ctx: Vue) {
  return [ctx.localePath('/'), ctx.localePath('/staffJoin/')]
}

const redirectToAuthPage = (ctx: Vue) => {
  if (!ctx.$route.name)
    throw new Error('context.route.nameが設定されていません')

  // 現在いるページにリダイレクトするとエラーが起きるため、条件分岐する
  // Unauthページにいる場合のみリダイレクト
  if (!authUrls(ctx).includes(ctx.$route.path)) {
    console.log('redirect to Authorize Page')
    ctx.$router.push(ctx.localePath('/'))
  }
}

const redirectToSelectCompanyPage = (ctx: Vue) => {
  if (!ctx.$route.name)
    throw new Error('context.route.nameが設定されていません')

  const selectCompanyPagePaths = [ctx.localePath('/selectCompany/')]
  if (!selectCompanyPagePaths.includes(ctx.$route.path)) {
    console.log('redirect to /selectCompany/')
    ctx.$router.push(ctx.localePath('/selectCompany/'))
  }
}

@Component({
  middleware: ['authorized', 'clientIdSetter', 'selectedCompanyChecker'],
  computed: {
    ...mapGetters('auth', ['userInfo']),
    ...mapGetters('company', ['staffInfo', 'company', 'isAdminOrManager']),
  },
  components: { TopNav, CompanyLogoPhoto },
  i18n: {
    messages: {
      ja: {
        futureAction: '将来のアクション',
        clientList: '顧客一覧',
        map: 'Map',
        actionHistory: 'アクション履歴',
        account: 'アカウント設定',
        handout: '配布物',
        managerMenu: 'マネージャーメニュー',
        manageStaff: 'スタッフ管理',
        settings: '設定',
        userSettings: 'User 設定',
        administratorMenu: '管理者メニュー',
        staffManagement: 'Staff管理',
        companySettings: '会社の設定',
        selectCompany: '会社を選択する',
      },
      en: {
        futureAction: 'Future Action',
        clientList: 'Client List',
        map: 'Map',
        actionHistory: 'Action History',
        account: 'Account Settings',
        handout: 'Handout',
        managerMenu: 'Manager Menu',
        manageStaff: 'Manage Staff',
        settings: 'Settings',
        userSettings: 'User Settings',
        staffManagement: 'Staff Management',
        companySettings: 'Company Settings',
        selectCompany: 'Select Company',
      },
    },
  },
})
export default class extends Vue {
  userInfo!: AppUser | null
  company!: AppCompany | null
  staffInfo!: AppStaff | null
  isAdminOrManager!: boolean
  isDrawerOpen = this.$device.isMobile ? false : true

  get items() {
    return [
      {
        title: this.$t('futureAction'),
        icon: 'mdi-inbox',
        to: this.localePath('/clients/actions/future/'),
      },
      {
        title: this.$t('clientList'),
        icon: 'mdi-account-multiple-outline',
        to: this.localePath('/clients/'),
      },
      {
        title: this.$t('map'),
        icon: 'mdi-map-marker-account',
        to: this.localePath('/clients/map/'),
      },
      {
        title: this.$t('actionHistory'),
        icon: 'mdi-message-processing-outline ',
        to: this.localePath('/clients/actions/'),
      },
    ]
  }

  get adminItems() {
    return [
      // 一旦削除
      {
        title: this.$t('staffManagement'),
        icon: 'mdi-account-multiple-outline',
        to: '/admin/manage/',
      },
      {
        title: this.$t('companySettings'),
        icon: 'mdi-cog-outline',
        to: this.localePath('/admin/companySettings/'),
      },
    ]
  }

  private signOut() {
    return auth.signOut()
  }

  created() {
    /**
     * 処理
     *
     * 各AuthとselectedCompanyについて、
     * 1. ログイン状態, 会社選択状態が解除されたときの初期化
     * 2. subscribe前の、初期化:
     *   ログアウトや、会社選択解除をせずに、次のアカウント、会社に切り替えたとき、
     *   1.の処理を通らないため、2.が必要
     *
     * 3. Auth, Companyデータのサブスクライブ
     */

    /**
     * Subscriptionリセットが必要なタイミング
     *
     * - Logoutするとき
     * - Logoutせず、アカウントを切り替えた時
     * - selectedCompanyIdが無くなった時
     * - selectedCompanyIdをリセットせず、次の会社に切り替えた時
     */

    unsubscribeAuthChange = auth.onAuthStateChanged(
      async (user: firebase.User | null) => {
        const firebaseUser = auth.currentUser

        if (!user || !firebaseUser) {
          // ログアウト時の、初期化
          console.log('default layout: logout')
          clearAuthSubscriptions()
          clearCompanySubscriptions()
          clearStoreAuthData(this)
          clearStoreCompanyData(this)
          redirectToAuthPage(this)
          return
        }

        // subscribe前に、一旦初期化
        clearAuthSubscriptions()
        clearCompanySubscriptions()
        clearStoreAuthData(this)
        clearStoreCompanyData(this)

        console.log('Subscribe AuthData')
        // currentUser取得
        const appUser$ = this.$deps.accountUseCase.getCurrentUser$()
        authSubscriptions.appUser = appUser$.subscribe((appUser) => {
          this.$store.commit('auth/setUserInfo', appUser)

          if (!appUser.selectedCompanyId) {
            clearCompanySubscriptions()
            clearStoreCompanyData(this)
            redirectToSelectCompanyPage(this)
            return
          }

          // // subscribe前に、一旦初期化
          clearCompanySubscriptions()
          clearStoreCompanyData(this)

          console.log('Subscribe CompanyData')
          // currentStaff set
          const appStaff$ = this.$deps.companyUseCase.getCurrentStaff$(
            appUser.selectedCompanyId,
            appUser.id
          )
          companySubscriptions.appStaff = appStaff$.subscribe((appStaff) => {
            this.$store.dispatch('company/setStaffInfo', appStaff)
          })

          // Company Set
          const company$ = this.$deps.companyUseCase.getCompany$(
            appUser.selectedCompanyId
          )
          companySubscriptions.company = company$.subscribe((company) => {
            this.$store.dispatch('company/setCompany', company)

            // allPeriod Set
            const allPeriod = this.$deps.companyUseCase.getAllPeriod(company)
            this.$store.dispatch('company/setAllPeriod', allPeriod)
          })

          // allStaff set
          const allStaff$ = this.$deps.staffUseCase.getAllStaff$(
            appUser.selectedCompanyId
          )
          companySubscriptions.allStaff = allStaff$.subscribe((allStaff) => {
            this.$store.dispatch('company/setAllStaff', allStaff)
          })

          // AllCategory set
          // アプリ内では、削除されていないCategoryを使う
          const allCategory$ =
            this.$deps.categoryUseCase.getAllUndeletedCategory$(
              appUser.selectedCompanyId
            )
          companySubscriptions.allCategory = allCategory$.subscribe(
            (allCategory) => {
              this.$store.dispatch('company/setAllCategory', allCategory)
            }
          )

          // All state set
          // アプリ内では、削除されていないStateを使う
          const allState$ = this.$deps.stateUseCase.getAllUndeletedState$(
            appUser.selectedCompanyId
          )
          companySubscriptions.allState = allState$.subscribe((allState) => {
            this.$store.dispatch('company/setAllState', allState)
          })

          // All Handout set
          // アプリ内では、削除されていないHandoutを使う
          const allHandout$ = this.$deps.handoutUseCase.getAllUndeletedHandout$(
            appUser.selectedCompanyId
          )
          companySubscriptions.allHandout = allHandout$.subscribe(
            (allHandout) => {
              this.$store.dispatch('company/setAllHandout', allHandout)
            }
          )

          //AllClient set
          const allClient$ = this.$deps.clientUseCase.getAllClient$(
            appUser.selectedCompanyId
          )
          companySubscriptions.allClient = allClient$.subscribe((allClient) => {
            this.$store.dispatch('client/setAllClient', allClient)
          })

          //All Action set
          const allCompanyAction$ =
            this.$deps.actionUseCase.getAllCompanyAction$(
              appUser.selectedCompanyId
            )
          companySubscriptions.allCompanyAction = allCompanyAction$.subscribe(
            (allCompanyAction) => {
              this.$store.dispatch(
                'action/setAllCompanyAction',
                allCompanyAction
              )
            }
          )
        })
      },
      (err: any) => console.error(err, 'onAuthStateChanged')
    )
  }

  destroyed() {
    unsubscribeAuthChange?.()
    unsubscribeAuthChange = null

    clearAuthSubscriptions()
    clearCompanySubscriptions()
    clearStoreAuthData(this)
    clearStoreCompanyData(this)
  }
}
