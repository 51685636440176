import { db, firestoreTimestamp } from '~/plugins/firebase'
import { collection, doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'
import { queryFn } from '~/entities'

export interface IHandoutEntity {
  companyId: string
  name: string
  note?: string
  photoPath?: string
  deleted: boolean
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface AppHandout extends IHandoutEntity {
  id: string
}

export interface FormHandout extends Pick<IHandoutEntity, 'name' | 'note' | 'photoPath'> { }

export class HandoutEntity {
  static readonly HANDOUTS = 'handouts'
  static readonly COMPANIES = 'companies'

  private getDocumentRef(
    companyId: string,
    handoutId: string
  ): firebase.firestore.DocumentReference {
    return this.getCollection(companyId).doc(handoutId)
  }

  getCollection(companyId: string): firebase.firestore.CollectionReference {
    const companyRef = db.collection(HandoutEntity.COMPANIES).doc(companyId)
    return companyRef.collection(HandoutEntity.HANDOUTS)
  }

  getQuery(companyId: string, queryFn?: queryFn): firebase.firestore.Query {
    const collection = this.getCollection(companyId)
    return queryFn ? queryFn(collection) : collection
  }

  getHandout$(companyId: string, handoutId: string): Observable<AppHandout> {
    const doc$ = doc(this.getDocumentRef(companyId, handoutId))

    return doc$.pipe(
      map((snapshot) => {
        const handout = snapshot.data() as IHandoutEntity
        const appHandout: AppHandout = { ...handout, id: snapshot.id }
        return appHandout
      })
    )
  }

  getHandout(companyId: string, handoutId: string): Promise<AppHandout> {
    return firstValueFrom(this.getHandout$(companyId, handoutId))
  }

  getAllHandout$(
    companyId: string,
    queryFn?: queryFn
  ): Observable<AppHandout[]> {
    return collection(this.getQuery(companyId, queryFn))
      .pipe(
        map((allSnapshot) => {
          return allSnapshot.map((snapshot) => {
            const entity = snapshot.data() as IHandoutEntity

            // Entity -> AppModel 変換
            return {
              ...entity,
              id: snapshot.id,
            }
          })
        })
      )
  }

  getAllUndeletedHandout$(companyId: string) {
    return this.getAllHandout$(
      companyId,
      query => query.where('deleted', '==', false)
    )
  }

  async addHandout(companyId: string, data: IHandoutEntity): Promise<void> {
    return await this.getCollection(companyId)
      .add(data)
      .then((_) => {
        console.log('Success addHandout')
      })
  }

  addHandoutByBatch(companyId: string, data: IHandoutEntity, batch: firebase.firestore.WriteBatch): firebase.firestore.WriteBatch {
    const handoutId = this.getCollection(companyId).doc().id
    return batch.set(
      this.getDocumentRef(companyId, handoutId), data
    )
  }

  updateHandout(
    companyId: string,
    handoutId: string,
    data: Partial<IHandoutEntity>
  ) {
    data.updatedAt = firestoreTimestamp()
    return this.getDocumentRef(companyId, handoutId).update(data)
  }

  //company配下のものはdeleteしない　影響範囲が大きいので
  async deleteHandout(companyId: string, handoutId: string) {
    const data: Pick<IHandoutEntity, 'deleted'> = {
      deleted: true,
    }
    await this.updateHandout(companyId, handoutId, data)
  }
}
