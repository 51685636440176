import { Plugin } from "@nuxt/types"

const formRules = {
  requireText: (value: any) => !!value || 'Input text.  入力してください。',
  requireSelect: (value: any) => !!value || 'Select anything.  選択してください。',
  requireNumber: (value: number) => typeof value === 'number' || 'Input Number.  数字を入力してください。',
  requireEmail: (value: string) => /.+@.+\..+/.test(value) || 'Input Email.  Emailを入力してください。'
}

declare module 'vue/types/vue' {
  interface Vue {
    $formRules: typeof formRules
  }
}

declare module '@nuxt/types' {
  interface Context {
    $formRules: typeof formRules
  }
}

const plugin: Plugin = (context, inject) => {
  inject('formRules', formRules)
}
export default plugin
