import {
  StaffEntity,
  IStaffEntity,
  AppStaff,
} from '~/entities/companies/staff/staffEntity'
import { Observable } from 'rxjs'
import { StaffStorage } from '~/storage/staff/staffStorage'
import firebase from 'firebase/app'

export class StaffUseCase {
  static readonly COMPANIES = 'companies'
  static readonly STAFF = 'staff'

  protected readonly staffEntity: StaffEntity
  protected readonly staffStorage: StaffStorage

  constructor(staffEntity: StaffEntity, staffStorage: StaffStorage) {
    this.staffEntity = staffEntity
    this.staffStorage = staffStorage
  }

  async addStaff(
    companyId: string,
    userId: string,
    data: IStaffEntity
  ): Promise<void> {
    return this.staffEntity.addStaff(companyId, userId, data)
  }

  getStaff$(companyId: string, staffId: string): Observable<AppStaff> {
    return this.staffEntity.getStaff$(companyId, staffId)
  }

  getAllStaff$(companyId: string): Observable<AppStaff[]> {
    return this.staffEntity.getAllStaff$(companyId)
  }

  updateStaff(companyId: string,
    staffId: string,
    data: IStaffEntity) {
    return this.staffEntity.updateStaff(companyId, staffId, data)
  }

  getStaffPhotoUrl(photoPath: string) {
    return this.staffStorage.getStaffPhotoUrl(photoPath)
  }

  uploadStaffPhoto(photo: File) {
    return this.staffStorage.uploadStaffPhoto(photo)
  }

  addStaffByBatch(
    companyId: string,
    userId: string,
    data: IStaffEntity,
    batch: firebase.firestore.WriteBatch
  ): firebase.firestore.WriteBatch {
    return this.staffEntity.addStaffByBatch(companyId, userId, data, batch)
  }
}
