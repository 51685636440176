import { Middleware } from "@nuxt/types";
import { AppClient } from '~/entities/clients/clientEntity'

/**
 * backSlash"/"無しのURLでアクセスした時のための、エラーハンドラー
 */
const middleware: Middleware = (ctx) => {
  if (!ctx.route.path.endsWith('/')) {
    // path: .../fields  => newPath: .../fields/
    const newPath =
      location.origin +
      ctx.route.fullPath.replace(ctx.route.path, ctx.route.path + '/')
    ctx.redirect(301, newPath);
  }
}
export default middleware
