import { Observable } from 'rxjs'
import {
  HandoutEntity,
  IHandoutEntity,
  AppHandout,
} from '~/entities/companies/handouts/handoutEntity'
import { HandoutStorage } from '~/storage/handout/handoutStorage'

/**
 * /admin/manageページ配下のCRUD用のコンポーネント
 *    - index
 *    - new
 *    - edit
 *
 * で使うサーバー側のロジックのみを書く
 * (Entity, Storage, Authentication等)
 */
export class HandoutUseCase {
  static readonly COMPANIES = 'companies'
  static readonly HANDOUTS = 'handouts'

  protected readonly handoutEntity: HandoutEntity
  protected readonly handoutStorage: HandoutStorage

  constructor(handoutEntity: HandoutEntity, handoutStorage: HandoutStorage) {
    this.handoutEntity = handoutEntity
    this.handoutStorage = handoutStorage
  }

  async addHandout(companyId: string, data: IHandoutEntity) {
    return await this.handoutEntity.addHandout(companyId, data)
  }

  getAllHandout$(companyId: string): Observable<AppHandout[]> {
    return this.handoutEntity.getAllHandout$(companyId)
  }

  getAllUndeletedHandout$(companyId: string): Observable<AppHandout[]> {
    return this.handoutEntity.getAllUndeletedHandout$(companyId)
  }

  updateHandout(
    companyId: string, handoutId: string,
    data: Partial<IHandoutEntity>) {
    return this.handoutEntity.updateHandout(companyId, handoutId, data)
  }

  deleteHandout(
    companyId: string,
    handoutId: string,
  ) {
    return this.handoutEntity.deleteHandout(companyId, handoutId)
  }
  getHandoutPhotoUrl(handoutPath: string) {
    return this.handoutStorage.getHandoutPhotoUrl(handoutPath)
  }

  async uploadHandoutPhoto(photo: File): Promise<string> {
    return this.handoutStorage.uploadHandoutPhoto(photo)
  }
}
