
import { Component, Vue } from 'vue-property-decorator'
import TopNav from '~/components/view/nav/topNav.vue'
import CompanyLogoPhoto from '~/components/view/layouts/companyLogoPhoto.vue'

@Component({
  computed: {},
  components: { TopNav, CompanyLogoPhoto },
  i18n: {},
})
export default class extends Vue {}
