import Vue from 'vue'
import { Observable } from 'rxjs'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import { authState } from 'rxfire/auth'
import User = firebase.User
import { firebaseConfig } from '~/config'

firebase.initializeApp(firebaseConfig)
const FIREBASE_REGION = 'us-central1'

export const auth = firebase.auth()
if (
  process.env.USE_AUTH_EMULATOR ===
  'true' /** dotenvで、booleanを使えないためstring型 */
) {
  auth.useEmulator(`http://localhost:9099`)
}
// FirebaseEmailの多言語化
firebase.auth().useDeviceLanguage();

export const db = firebase.firestore()
if (
  process.env.USE_DB_EMULATOR ===
  'true' /** dotenvで、booleanを使えないためstring型 */
) {
  db.useEmulator('localhost', 8080)
}

export const storage = firebase.storage()
if (
  process.env.USE_STORAGE_EMULATOR ===
  'true' /** dotenvで、booleanを使えないためstring型 */
) {
  storage.useEmulator('localhost', 9199)
}

export const functions = () => {
  const funcs = firebase.app().functions(FIREBASE_REGION)
  if (
    process.env.USE_FUNCTIONS_EMULATOR ===
    'true' /** dotenvで、booleanを使えないためstring型 */
  ) {
    funcs.useEmulator('localhost', 5001)
  }
  return funcs
}

export let analytics: firebase.analytics.Analytics

/** test環境では読み込まない エラーのため */
if (process.env.NODE_ENV !== 'test') {
  analytics = firebase.analytics()
}

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

// https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
// signInするたびに、認証するgoogleアカウントを選択できるようになる
googleAuthProvider.setCustomParameters({
  prompt: "select_account"
})
export const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
export function getProviderForProviderId(
  providerId: typeof firebase.auth.GoogleAuthProvider.PROVIDER_ID | typeof firebase.auth.TwitterAuthProvider.PROVIDER_ID
) {
  if (providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
    console.log('get Google Provider')
    return googleAuthProvider
  } else if (providerId === firebase.auth.TwitterAuthProvider.PROVIDER_ID) {
    console.log('get Twitter Provider')
    return twitterAuthProvider
  }
}

export const getCurrentFirebaseUser = (): User => {
  const user = auth.currentUser
  if (!user) {
    throw new Error('no user. call this after login')
  }
  return user
}

/**
 * タイムスタンプ周りの参考サイト
 * https://chaika.hatenablog.com/entry/2021/04/18/083000
 */
export const firestoreTimestamp = (): firebase.firestore.Timestamp => {
  return firebase.firestore.Timestamp.fromDate(new Date())
}

Vue.use(firestorePlugin)
