import firebase from 'firebase'
import { format, isDate } from 'date-fns'
import { Plugin } from '@nuxt/types'

export function formatTimestampToDateString(timestamp: firebase.firestore.Timestamp): string {
  return format(timestamp.toDate(), 'yyyy-MM-dd')
}

export function formatDateStringToTimestamp(dateString: string): firebase.firestore.Timestamp {
  const newDate = new Date(dateString)
  if (!isDate(newDate)) throw new Error(`${dateString} is not dateString.`)

  return firebase.firestore.Timestamp.fromDate(
    newDate
  )
}

export function formatDatePickerValueToBirthday(value: string) {
  return value.substring(5, 10)
}

export function isDev(): boolean {
  return process.env.NODE_ENV === 'development'
}

export function sleep(second: number) {
  return new Promise(resolve => setTimeout(resolve, second * 1000))
}

declare module 'vue/types/vue' {
  interface Vue {
    $helpers: typeof helpers
  }
}
declare module '@nuxt/types' {
  interface Context {
    $helpers: typeof helpers
  }
}

const helpers = { formatTimestampToDateString, isDev: isDev(), formatDatePickerValueToBirthday }
const plugin: Plugin = (ctx, inject) => {
  inject('helpers', helpers)
}
export default plugin
