import firebase from 'firebase'
import { CompanyEntity, ICompanyEntity } from '~/entities/companies/companyEntity'
import { AppStaff, IStaffEntity, RoleType, StaffEntity } from '~/entities/companies/staff/staffEntity'
import {
  IUserEntity,
  UserEntity,
} from '~/entities/users/userEntity'
import { db, firestoreTimestamp } from '~/plugins/firebase'
import { CompanyUseCase } from '../company/companyUseCase'

// Formで変更可能なUserデータ
export type FormUser = Pick<AppStaff, 'name' | 'kana' | 'role' | 'note'>

export interface LinkAccountPayload {
  linkedCredential: firebase.auth.AuthCredential
  linkedProvider: firebase.auth.AuthProvider
  callbackPath: string
}

export interface FirstSigninHandlerArgs {
  uid: string
  email: string
}

export class AccountUseCase {
  protected readonly userEntity: UserEntity
  protected readonly staffEntity: StaffEntity
  protected readonly companyEntity: CompanyEntity
  protected readonly companyUseCase: CompanyUseCase

  constructor(userEntity: UserEntity, staffEntity: StaffEntity, companyEntity: CompanyEntity, companyUseCase: CompanyUseCase) {
    this.userEntity = userEntity
    this.staffEntity = staffEntity
    this.companyEntity = companyEntity
    this.companyUseCase = companyUseCase
  }

  getUser$(userId: string) {
    return this.userEntity.getUser$(userId)
  }

  getCurrentUser$() {
    return this.userEntity.getCurrentUser$()
  }

  deleteUser(uid: string) {
    return this.userEntity.deleteUser(uid)
  }

  addUser(userId: string, data: IUserEntity) {
    return this.userEntity.addUser(userId, data)
  }
  addUserByBatch(userId: string, data: IUserEntity, batch: firebase.firestore.WriteBatch) {
    return this.userEntity.addUserByBatch(userId, data, batch)
  }

  updateUser(userId: string, data: Partial<IUserEntity> & Pick<IUserEntity, 'updatedAt' | 'allCompanyId' | 'selectedCompanyId'>) {
    return this.userEntity.updateUser(userId, data)
  }

  updateUserByBatch(userId: string, data: Partial<IUserEntity> & Pick<IUserEntity, 'updatedAt' | 'allCompanyId' | 'selectedCompanyId'>, batch: firebase.firestore.WriteBatch
  ): firebase.firestore.WriteBatch {
    return this.userEntity.updateUserByBatch(userId, data, batch)
  }

  selectCompany(userId: string, selectedCompanyId: IUserEntity["selectedCompanyId"]) {
    return this.userEntity.selectCompany(userId, selectedCompanyId)
  }

  async addNewUser({ email, uid }: FirstSigninHandlerArgs) {
    const companyId = db.collection(CompanyEntity.COMPANIES).doc().id
    const batch = db.batch()

    // user追加
    const userData: IUserEntity = {
      email,
      companyId: companyId,
      createdAt: firestoreTimestamp(),
      updatedAt: firestoreTimestamp(),
      allCompanyId: [companyId],
      selectedCompanyId: companyId,
    }
    this.userEntity.addUserByBatch(uid, userData, batch)

    // adminStaff追加
    const adminStaff: IStaffEntity = {
      name: email,
      role: RoleType.companyAdmin,
      deleted: false,
      email: email,
      companyId: companyId,
      createdAt: firestoreTimestamp(),
      updatedAt: firestoreTimestamp(),
    }
    this.staffEntity.addStaffByBatch(
      companyId,
      uid,
      adminStaff,
      batch
    )

    //company追加
    const companyData: ICompanyEntity = {
      name: email,
      createUserId: uid,
      safeDays: 7,
      warningDays: 14,
      createdAt: firestoreTimestamp(),
      updatedAt: firestoreTimestamp(),
    }
    this.companyUseCase.addCompanyByBatch(companyId, companyData, batch)

    await batch.commit()
  }
}
