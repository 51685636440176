import { Context, Middleware } from "@nuxt/types";
import { Vue } from 'vue-property-decorator';
import { AppUser } from "~/entities/users/userEntity";

export const redirectToSelectCompanyPage = (ctx: Context) => {
  if (!ctx.route.name) throw new Error('context.route.nameが設定されていません')

  const selectCompanyPagePaths = [
    ctx.localePath('/selectCompany/')
  ]
  if (!selectCompanyPagePaths.includes(ctx.route.path)) {
    console.log('redirect to /selectCompany/')
    ctx.redirect(
      ctx.localePath('/selectCompany/')
    )
  }
}

/**
 * SelectedCompanyIdがない時、任意のリンクをクリックして、
 * 会社の詳細ページが表示されないように必要
 */
const middleware: Middleware = (ctx) => {
  const userInfo: AppUser | null = ctx.store.getters['auth/userInfo']
  if (userInfo) {
    if (!userInfo.selectedCompanyId) {
      return redirectToSelectCompanyPage(ctx)
    }
  }
}
export default middleware
