import { Plugin } from '@nuxt/types'
import { ClientEntity } from '~/entities/clients/clientEntity'
import { StaffInvitationEntity } from '~/entities/companies/staffInvitations/staffInvitationEntity'
import { UserEntity } from '~/entities/users/userEntity'
import { AccountUseCase } from '~/usecase/account/accountUseCase'
import { ClientUseCase } from '~/usecase/client/clientUseCase'
import { CompanyUseCase } from '~/usecase/company/companyUseCase'
import { StaffEntity } from '~/entities/companies/staff/staffEntity'
import { CompanyEntity } from '~/entities/companies/companyEntity'
import { CategoryUseCase } from '~/usecase/company/categoryUseCase'
import { CategoryEntity } from '~/entities/companies/categories/categoryEntity'
import { StateEntity } from '~/entities/companies/state/stateEntity'
import { HandoutEntity } from '~/entities/companies/handouts/handoutEntity'
import { StaffUseCase } from '~/usecase/company/staffUseCase'
import { StaffInvitationUseCase } from '~/usecase/company/staffInvitationUseCase'
import { StateUseCase } from '~/usecase/company/stateUseCase'
import { ClientStorage } from '~/storage/client/clientStorage'
import { ActionUseCase } from '~/usecase/action/actionUseCase'
import { ActionEntity } from '~/entities/actions/actionEntity'
import { HandoutUseCase } from '~/usecase/company/handoutUseCase'
import { HandoutStorage } from '~/storage/handout/handoutStorage'
import { StaffStorage } from '~/storage/staff/staffStorage'
import { FeedbackUseCase } from '~/usecase/feedback/feedbackUseCase'
import { FeedbackStorage } from '~/storage/feedback/feedbackStorage'
import { FeedbackEntity } from '~/entities/feedbacks/feedbackEntity'
import { CompanyStorage } from '~/storage/company/companyStorage'
import { ActionStorage } from '~/storage/action/actionStorage'

export interface Dependencies {
  accountUseCase: AccountUseCase
  clientUseCase: ClientUseCase
  companyUseCase: CompanyUseCase
  categoryUseCase: CategoryUseCase
  staffUseCase: StaffUseCase
  staffInvitationUseCase: StaffInvitationUseCase
  stateUseCase: StateUseCase
  actionUseCase: ActionUseCase
  handoutUseCase: HandoutUseCase
  feedbackUseCase: FeedbackUseCase
}

declare module 'vue/types/vue' {
  interface Vue {
    $deps: Dependencies
  }
}
declare module '@nuxt/types' {
  interface Context {
    $deps: Dependencies
  }
}

const plugin: Plugin = (context, inject) => {
  // Entity
  const userEntity: UserEntity = new UserEntity()
  const clientEntity: ClientEntity = new ClientEntity()
  const companyEntity: CompanyEntity = new CompanyEntity()
  const staffEntity: StaffEntity = new StaffEntity()
  const staffInvitationEntity: StaffInvitationEntity =
    new StaffInvitationEntity()
  const categoryEntity: CategoryEntity = new CategoryEntity()
  const stateEntity: StateEntity = new StateEntity()
  const handoutEntity: HandoutEntity = new HandoutEntity()
  const actionEntity: ActionEntity = new ActionEntity()
  const feedbackEntity: FeedbackEntity = new FeedbackEntity()

  // Storage
  const companyStorage: CompanyStorage = new CompanyStorage()
  const staffStorage: StaffStorage = new StaffStorage()
  const clientStorage: ClientStorage = new ClientStorage()
  const actionStorage: ActionStorage = new ActionStorage()
  const handoutStorage: HandoutStorage = new HandoutStorage()
  const feedbackStorage: FeedbackStorage = new FeedbackStorage()

  // UseCase
  const clientUseCase: ClientUseCase = new ClientUseCase(
    clientEntity,
    clientStorage
  )
  const companyUseCase: CompanyUseCase = new CompanyUseCase(
    staffEntity,
    staffInvitationEntity,
    companyEntity,
    categoryEntity,
    stateEntity,
    handoutEntity,
    companyStorage
  )
  const accountUseCase: AccountUseCase = new AccountUseCase(userEntity, staffEntity, companyEntity, companyUseCase)
  const categoryUseCase: CategoryUseCase = new CategoryUseCase(categoryEntity)
  const staffUseCase: StaffUseCase = new StaffUseCase(staffEntity, staffStorage)
  const staffInvitationUseCase: StaffInvitationUseCase =
    new StaffInvitationUseCase(staffInvitationEntity)
  const stateUseCase: StateUseCase = new StateUseCase(stateEntity)
  const actionUseCase: ActionUseCase = new ActionUseCase(
    actionEntity,
    handoutEntity,
    actionStorage
  )
  const handoutUseCase: HandoutUseCase = new HandoutUseCase(
    handoutEntity,
    handoutStorage
  )
  const feedbackUseCase: FeedbackUseCase = new FeedbackUseCase(
    feedbackEntity,
    feedbackStorage
  )

  const deps: Dependencies = {
    accountUseCase,
    clientUseCase,
    companyUseCase,
    categoryUseCase,
    staffUseCase,
    staffInvitationUseCase,
    stateUseCase,
    actionUseCase,
    handoutUseCase,
    feedbackUseCase,
  }
  inject('deps', deps)
}
export default plugin
