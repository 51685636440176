import { db, firestoreTimestamp, getCurrentFirebaseUser } from '~/plugins/firebase'
import { doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'

export interface IUserEntity {
  email: string
  companyId: string // 自分が作った会社のID
  allCompanyId: string[]
  selectedCompanyId: string | null
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}
export interface AppUser extends IUserEntity {
  id: string
}

export class UserEntity {
  static readonly collectionName = 'users'

  private getRef(userId: string): firebase.firestore.DocumentReference {
    return db.collection(UserEntity.collectionName).doc(userId)
  }

  private getCollection() {
    return db.collection(UserEntity.collectionName)
  }

  getUser$(userId: string): Observable<AppUser> {
    const doc$ = doc(this.getRef(userId))

    return doc$.pipe(
      map((snapshot) => {
        console.log('subscribe USER ENTITY')
        const user = snapshot.data() as IUserEntity
        const appUser: AppUser = { ...user, id: snapshot.id }
        return appUser
      })
    )
  }

  getUser(userId: string): Promise<AppUser> {
    return firstValueFrom(this.getUser$(userId))
  }

  getCurrentUser$(): Observable<AppUser> {
    return this.getUser$(getCurrentFirebaseUser().uid)
  }

  async addUser(userId: string, data: IUserEntity): Promise<void> {
    return await this.getRef(userId)
      .set(data)
      .then((_) => {
        console.log('success addUser')
        // ?? how about error handling?
      })
  }
  addUserByBatch(userId: string, data: IUserEntity, batch: firebase.firestore.WriteBatch): firebase.firestore.WriteBatch {
    return batch.set(
      this.getRef(userId),
      data
    )
  }

  updateUser(
    userId: string,
    data: Partial<IUserEntity> & Pick<IUserEntity, 'updatedAt' | 'allCompanyId' | 'selectedCompanyId'>
  ) {
    data.updatedAt = firestoreTimestamp()
    return this.getRef(userId).update(data)
  }

  updateUserByBatch(
    userId: string,
    data: Partial<IUserEntity> & Pick<IUserEntity, 'updatedAt' | 'allCompanyId' | 'selectedCompanyId'>,
    batch: firebase.firestore.WriteBatch
  ): firebase.firestore.WriteBatch {
    data.updatedAt = firestoreTimestamp()
    return batch.update(
      this.getRef(userId),
      data
    )
  }

  deleteUser(userId: string) {
    return this.getRef(userId).delete()
  }

  selectCompany(userId: string, selectedCompanyId: IUserEntity["selectedCompanyId"]) {
    const data: Pick<IUserEntity, 'selectedCompanyId' | 'updatedAt'> = {
      selectedCompanyId,
      updatedAt: firestoreTimestamp()
    }
    return this.getRef(userId).update(data)
  }
}
