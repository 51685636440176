import { db } from '~/plugins/firebase'
import { doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'

export interface ICompanyEntity {
  name: string
  target?: string
  note?: string
  address?: string
  latitude?: number
  longitude?: number
  email?: string
  url?: string
  establishedDate?: string
  logoPhotoPath?: string
  createUserId: string
  safeDays: number
  warningDays: number
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface AppCompany extends ICompanyEntity {
  id: string
}

export const formCompanyKeys = [
  'name',
  'target',
  'note',
  'address',
  'latitude',
  'longitude',
  'email',
  'url',
  'establishedDate',
  'logoPhotoPath'
] as const

export interface FormCompany extends Pick<
  ICompanyEntity,
  typeof formCompanyKeys[number]
> { }

export class CompanyEntity {
  static readonly COMPANIES = 'companies'

  private getRef(companyId: string): firebase.firestore.DocumentReference {
    return this.getCollection().doc(companyId)
  }

  private getCollection() {
    return db.collection(CompanyEntity.COMPANIES)
  }

  getCompany$(companyId: string): Observable<AppCompany> {
    const doc$ = doc(this.getRef(companyId))
    return doc$.pipe(
      map((snapshot) => {
        const company = snapshot.data() as ICompanyEntity
        const appCompany = { ...company, id: snapshot.id }
        return appCompany
      })
    )
  }

  getCompany(companyId: string): Promise<AppCompany> {
    return firstValueFrom(this.getCompany$(companyId))
  }

  async addCompany(companyId: string, data: ICompanyEntity) {
    return await this.getRef(companyId)
      .set(data)
      .then((_) => {
        console.log('Success addCompany')
      })
  }

  addCompanyByBatch(companyId: string, data: ICompanyEntity, batch: firebase.firestore.WriteBatch): firebase.firestore.WriteBatch {
    return batch.set(this.getRef(companyId), data)
  }

  async updateCompany(companyId: string, data: ICompanyEntity) {
    return await db
      .collection(CompanyEntity.COMPANIES)
      .doc(companyId)
      .update(data)
  }

  updatePeriod(
    companyId: string,
    data: Pick<ICompanyEntity, 'safeDays'> | Pick<ICompanyEntity, 'warningDays'>
  ) {
    return this.getRef(companyId).update(data)
  }
}
