import { db, storage } from '~/plugins/firebase'

export class StaffStorage {
  async getStaffPhotoUrl(photoPath: string): Promise<string> {
    //'staffs/ZAhpkpWwkuEBhPzN4gmK/ staffs/ZAhpkpWwkuEBhPzN4gmK      100x100.png'

    const storageRef = storage.ref(photoPath + "_100x100")
    const staffPhoto = await storageRef.getDownloadURL()
    return staffPhoto
  }

  /**
   * @return staffPhotoPath
   */
  async uploadStaffPhoto(photo: File): Promise<string> {
    const uuid = db.collection('a').doc().id
    const staffPhotoPath = `staffs/${uuid}`
    const metadata = {
      name: uuid,
      contentType: 'image/jpeg',
    }
    const storageRef = storage.ref(staffPhotoPath)
    await storageRef.put(photo, metadata)

    /**
     * リサイズ後の画像が保存されるまで待機
     */
    while (true) {
      try {
        await this.getStaffPhotoUrl(staffPhotoPath)
        break
      } catch {
        // wait and retry
        await new Promise(resolve => setTimeout(resolve, 2000))
      }
    }

    console.log('uploaded Staff image')
    return staffPhotoPath
  }
}
