import { collection, doc } from 'rxfire/firestore'
import firebase from 'firebase/app'
import { db, firestoreTimestamp } from '~/plugins/firebase'
import { map, Observable, switchMap, tap } from 'rxjs'

import { queryFn } from '~/entities'

export interface IStateEntity {
  companyId: string
  name: string
  note?: string
  deleted: boolean
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface AppState extends IStateEntity {
  id: string
}
export interface FormState extends Pick<AppState, 'name' | 'note'> { }

export class StateEntity {
  static readonly STATES = 'states'
  static readonly COMPANEIS = 'companies'
  constructor() { }

  getDocumentRef(
    companyId: string,
    stateId: string
  ): firebase.firestore.DocumentReference {
    return this.getCollection(companyId).doc(stateId)
  }

  getCollection(companyId: string): firebase.firestore.CollectionReference {
    const companyRef = db.collection(StateEntity.COMPANEIS).doc(companyId)
    return companyRef.collection(StateEntity.STATES)
  }

  getQuery(companyId: string, queryFn?: queryFn): firebase.firestore.Query {
    const collection = this.getCollection(companyId)
    return queryFn ? queryFn(collection) : collection
  }

  getState$(companyId: string, stateId: string): Observable<AppState> {
    const docSnapshot$ = doc(this.getDocumentRef(companyId, stateId))
    return docSnapshot$.pipe(
      map((docSnapshot) => {
        const state: IStateEntity = docSnapshot.data() as IStateEntity
        const appState: AppState = {
          ...state,
          id: docSnapshot.id,
        }
        return appState
      })
    )
  }

  getAllState$(companyId: string, queryFn?: queryFn): Observable<AppState[]> {
    const collectionSnapshot = collection(this.getQuery(companyId, queryFn))
    return collectionSnapshot.pipe(
      map((allSnapshot) => {
        const allState: AppState[] = []
        allSnapshot.forEach((snapshot) => {
          const state = snapshot.data() as IStateEntity
          const appState = {
            ...state,
            id: snapshot.id,
          }
          allState.push(appState)
        })
        return allState
      })
    )
  }

  getAllUndeletedState$(companyId: string) {
    return this.getAllState$(
      companyId,
      query => query.where('deleted', '==', false)
    )
  }

  async addState(companyId: string, data: IStateEntity) {
    await this.getCollection(companyId).add(data)
  }

  addStateByBatch(companyId: string, data: IStateEntity, batch: firebase.firestore.WriteBatch): firebase.firestore.WriteBatch {
    const stateId = this.getCollection(companyId).doc().id
    return batch.set(
      this.getDocumentRef(companyId, stateId), data
    )
  }

  async updateState(companyId: string, stateId: string, data: Partial<IStateEntity>) {
    data.updatedAt = firestoreTimestamp()
    await this.getDocumentRef(companyId, stateId).update(data)
  }

  async deleteState(
    companyId: string,
    stateId: string,
  ) {
    const data: Pick<IStateEntity, 'deleted'> = {
      deleted: true
    }
    await this.getDocumentRef(companyId, stateId).update(data)
  }
}
