import firebase from 'firebase/app'
import { StaffEntity } from '~/entities/companies/staff/staffEntity'
import { firestoreTimestamp } from '~/plugins/firebase'
import {
  AppCompany,
  CompanyEntity,
  ICompanyEntity,
} from '~/entities/companies/companyEntity'
import {
  CategoryEntity,
  ICategoryEntity,
} from '~/entities/companies/categories/categoryEntity'
import {
  StateEntity,
  IStateEntity,
} from '~/entities/companies/state/stateEntity'
import {
  HandoutEntity,
  IHandoutEntity,
} from '~/entities/companies/handouts/handoutEntity'
import { StaffInvitationEntity } from '~/entities/companies/staffInvitations/staffInvitationEntity'
import { AppPeriod, PeriodId } from '~/entities/companies/periods/periodEntity'
import { Observable } from 'rxjs'
import { CompanyStorage } from '~/storage/company/companyStorage'

/**
 * /company/manageページ配下のCRUD用のコンポーネント
 *    - index
 *    - new
 *    - edit
 *
 * で使うサーバー側のロジックのみを書く
 * (Entity, Storage, Authentication等)
 */
export class CompanyUseCase {
  static readonly COMPANIES = 'companies'
  protected readonly staffEntity: StaffEntity
  protected readonly staffInvitationEntity: StaffInvitationEntity
  protected readonly companyEntity: CompanyEntity
  protected readonly categoryEntity: CategoryEntity
  protected readonly stateEntity: StateEntity
  protected readonly handoutEntity: HandoutEntity
  protected readonly companyStorage: CompanyStorage

  constructor(
    staffEntity: StaffEntity,
    staffInvitationEntity: StaffInvitationEntity,
    companyEntity: CompanyEntity,
    categoryEntity: CategoryEntity,
    stateEntity: StateEntity,
    handoutEntity: HandoutEntity,
    companyStorage: CompanyStorage
  ) {
    this.staffEntity = staffEntity
    this.staffInvitationEntity = staffInvitationEntity
    this.companyEntity = companyEntity
    this.categoryEntity = categoryEntity
    this.stateEntity = stateEntity
    this.handoutEntity = handoutEntity
    this.companyStorage = companyStorage
  }

  //here
  initCompany(companyId: string) {
    /**
     * 初期データ投入
     * Categories
     * Status
     * Handouts
     */
    const categoryDatas = this.makeCategoryData(companyId)
    categoryDatas.forEach((category) => {
      this.categoryEntity.addCategory(companyId, category)
    })
    const stateDatas = this.makeStateData(companyId)
    stateDatas.forEach((state) => {
      this.stateEntity.addState(companyId, state)
    })
    const handoutDatas = this.makeHandoutData(companyId)
    handoutDatas.forEach((handout) => {
      this.handoutEntity.addHandout(companyId, handout)
    })
  }
  initCompanyByBatch(companyId: string, batch: firebase.firestore.WriteBatch) {
    /**
     * 初期データ投入
     * Categories
     * Status
     * Handouts
     */
    const categoryDatas = this.makeCategoryData(companyId)
    categoryDatas.forEach((category) => {
      this.categoryEntity.addCategoryByBatch(companyId, category, batch)
    })
    const stateDatas = this.makeStateData(companyId)
    stateDatas.forEach((state) => {
      this.stateEntity.addStateByBatch(companyId, state, batch)
    })
    const handoutDatas = this.makeHandoutData(companyId)
    handoutDatas.forEach((handout) => {
      this.handoutEntity.addHandoutByBatch(companyId, handout, batch)
    })
  }

  getCompany$(companyId: string): Observable<AppCompany> {
    return this.companyEntity.getCompany$(companyId)
  }

  getCompany(companyId: string): Promise<AppCompany> {
    return this.companyEntity.getCompany(companyId)
  }

  async addCompany(companyId: string, data: ICompanyEntity) {
    await this.companyEntity.addCompany(companyId, data)
    await this.initCompany(companyId)
  }

  addCompanyByBatch(companyId: string, data: ICompanyEntity, batch: firebase.firestore.WriteBatch) {
    this.companyEntity.addCompanyByBatch(companyId, data, batch)
    this.initCompanyByBatch(companyId, batch)
  }

  async updateCompany(companyId: string, data: ICompanyEntity) {
    await this.companyEntity.updateCompany(companyId, data)
  }

  getCurrentStaff$(companyId: string, staffId: string) {
    return this.staffEntity.getStaff$(companyId, staffId)
  }

  getAllPeriod(company: AppCompany): AppPeriod[] {
    return [
      {
        id: PeriodId.Safe,
        title: 'Good!',
        days: company.safeDays,
        intervalText: `${company.safeDays}日以内`,
        editable: true
      }, {
        id: PeriodId.Warning,
        title: 'Attention',
        days: company.warningDays,
        intervalText: `${company.warningDays}日以内`,
        editable: true
      }, {
        id: PeriodId.Dangerous,
        title: 'Dangerously',
        days: company.warningDays + 1,
        intervalText: `${company.warningDays + 1}日以上`,
        editable: false
      }
    ]
  }

  updatePeriod(
    companyId: string,
    data: Pick<ICompanyEntity, 'safeDays'> | Pick<ICompanyEntity, 'warningDays'>
  ) {
    return this.companyEntity.updatePeriod(companyId, data)
  }

  getCompanyPhotoUrl(photoPath: string): Promise<string> {
    return this.companyStorage.getCompanyPhotoUrl(photoPath)
  }

  uploadCompanyPhoto(photo: File): Promise<string> {
    return this.companyStorage.uploadCompanyPhoto(photo)
  }

  makeCategoryData(companyId: string): ICategoryEntity[] {
    const categoryInitData: ICategoryEntity[] = [
      {
        companyId: companyId,
        name: '友達',
        note: '',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Friend',
        note: '',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: '好きなお店',
        note: '',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Favorite shop',
        note: '',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: '医者',
        note: '',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Doctor',
        note: '',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
    ]
    return categoryInitData
  }

  makeStateData(companyId: string): IStateEntity[] {
    const stateInitData: IStateEntity[] = [
      {
        companyId: companyId,
        name: '興味あり',
        note: 'パンフレットを読んでいた',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Interested',
        note: 'He reading pamphlet',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'お試し期間中',
        note: '化粧品Aの試供品を試している',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Trial period',
        note: 'She is trying cosmetic A',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: '契約後',
        note: '毎月持っていく必要がある',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'After the contract',
        note: 'I need to bring it every month',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
    ]
    return stateInitData
  }

  makeHandoutData(companyId: string): IHandoutEntity[] {
    const initHandoutData = [
      {
        companyId: companyId,
        name: 'デザインカタログ',
        note: '女性服',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Clothes Catalog',
        note: 'Woman clothes',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: '花',
        note: 'プレゼント',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
      {
        companyId: companyId,
        name: 'Flower',
        note: 'gift',
        deleted: false,
        createdAt: firestoreTimestamp(),
        updatedAt: firestoreTimestamp(),
      },
    ]
    return initHandoutData
  }
}
