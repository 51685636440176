import { db, storage } from '~/plugins/firebase'

export class FeedbackStorage {
  async getFeedbackPhotoUrl(photoPath: string): Promise<string> {
    //'feedbacks/ZAhpkpWwkuEBhPzN4gmK/ feedbacks/ZAhpkpWwkuEBhPzN4gmK      100x100.png'

    const storageRef = storage.ref(photoPath + "_100x100")
    const feedbackPhoto = await storageRef.getDownloadURL()
    return feedbackPhoto
  }

  /**
   * @return feedbackPhotoPath
   */
  async uploadFeedbackPhoto(photo: File): Promise<string> {
    const uuid = db.collection('a').doc().id
    const feedbackPhotoPath = `feedbacks/${uuid}`
    const metadata = {
      name: uuid,
      contentType: 'image/jpeg',
    }
    const storageRef = storage.ref(feedbackPhotoPath)
    await storageRef.put(photo, metadata)

    /**
     * リサイズ後の画像が保存されるまで待機
     */
    while (true) {
      try {
        await this.getFeedbackPhotoUrl(feedbackPhotoPath)
        break
      } catch {
        // wait and retry
        await new Promise(resolve => setTimeout(resolve, 2000))
      }
    }
    
    console.log('uploaded Feedback image')
    return feedbackPhotoPath
  }
}
