export const firebaseConfig = {
  apiKey: process.env.FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.FIREBASE_CONFIG_MEASUREMENT_ID,
};
if (!firebaseConfig.apiKey) throw new Error('環境変数FIREBASE_CONFIG_API_KEYが設定されていません')
if (!firebaseConfig.authDomain) throw new Error('環境変数FIREBASE_CONFIG_AUTH_DOMAINが設定されていません')
if (!firebaseConfig.projectId) throw new Error('環境変数FIREBASE_CONFIG_PROJECT_IDが設定されていません')
if (!firebaseConfig.storageBucket) throw new Error('環境変数FIREBASE_CONFIG_STORAGE_BUCKETが設定されていません')
if (!firebaseConfig.messagingSenderId) throw new Error('環境変数FIREBASE_CONFIG_MESSAGING_SENDER_IDが設定されていません')
if (!firebaseConfig.appId) throw new Error('環境変数FIREBASE_CONFIG_APP_IDが設定されていません')
if (!firebaseConfig.measurementId) throw new Error('環境変数FIREBASE_CONFIG_MEASUREMENT_IDが設定されていません')