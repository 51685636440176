// 型付け参考 https://typescript.nuxtjs.org/ja/cookbook/store#vanilla

import { GetterTree, ActionTree, MutationTree } from 'vuex'

export interface RootState { }

export const state: any = () => ({
  userInfo: null
})

export const mutations: MutationTree<RootState> = {
}

export const actions: ActionTree<RootState, RootState> = {
}

export const getters: GetterTree<RootState, RootState> = {
}