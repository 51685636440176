import { db, getCurrentFirebaseUser } from '~/plugins/firebase'
import { doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'

/**
 * 1-エラー内容タイトル、要望タイトル
  2-エラーが発生したurl、要望のURL
  3-エラー内容 、要望のURL
  4-画像
  5-Giff画像 のURL
  Gyazoへのリンクをはる
 */

export interface IFeedbackEntity {
  userId: string
  companyId: string
  staffId: string
  title: string
  content: string
  photoPath?: string
  replyMail: boolean // 返信されたかどうか
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface AppFeedback extends IFeedbackEntity {
  id: string
}

export interface FormFeedback extends Pick<IFeedbackEntity, 'title' | 'content' | 'photoPath'> { }

export class FeedbackEntity {
  static readonly FEEDBACKS = 'feedbacks'

  private getRef(feedbackId: string): firebase.firestore.DocumentReference {
    return this.getCollection().doc(feedbackId)
  }

  private getCollection() {
    return db.collection(FeedbackEntity.FEEDBACKS)
  }

  getFeedback$(feedbackId: string): Observable<AppFeedback> {
    const doc$ = doc(this.getRef(feedbackId))
    return doc$.pipe(
      map((snapshot) => {
        const feedback = snapshot.data() as IFeedbackEntity
        const appFeedback = { ...feedback, id: snapshot.id }
        return appFeedback
      })
    )
  }

  getFeedback(feedbackId: string): Promise<AppFeedback> {
    return firstValueFrom(this.getFeedback$(feedbackId))
  }

  async addFeedback(data: IFeedbackEntity) {
    return await this.getCollection().add(data)
      .then((_) => {
        console.log('Success addFeedback')
      })
  }

  async updateFeedback(feedbackId: string, data: IFeedbackEntity) {
    await this.getRef(feedbackId)
      .update(data)
      .then((_=>{
        console.log('Success update Feedback')
      }))
  }
}
