import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { AppCategory } from '~/entities/companies/categories/categoryEntity'
import { AppStaff, RoleType } from '~/entities/companies/staff/staffEntity'
import { RootState } from '..'
import { AppState } from '~/entities/companies/state/stateEntity'
import { AppCompany } from '~/entities/companies/companyEntity'
import { AppPeriod } from '~/entities/companies/periods/periodEntity'
import { AppHandout } from '~/entities/companies/handouts/handoutEntity'

//companyが管理するものすべて記載 client以外
export interface CompanyState {
  staffInfo: AppStaff
  company: AppCompany
  allCategory: AppCategory[]
  allStaff: AppStaff[]
  allState: AppState[]
  allPeriod: AppPeriod[]
  allHandout: AppHandout[]
}

export const state: any = () => ({
  staffInfo: null,
  company: null,
  allCategory: null,
  allStaff: null,
  allState: null,
  allPeriod: null,
  allHandout: null,
})

export const mutations: MutationTree<CompanyState> = {
  setStaffInfo(state, data: AppStaff) {
    state.staffInfo = data
  },
  setCompany(state, data: AppCompany) {
    state.company = data
  },
  setAllCategory(state, data: AppCategory[]) {
    state.allCategory = data
  },
  setAllStaff(state, data: AppStaff[]) {
    state.allStaff = data
  },
  setAllState(state, data: AppState[]) {
    state.allState = data
  },
  setAllPeriod(state, data: AppPeriod[]) {
    state.allPeriod = data
  },
  setAllHandout(state, data: AppHandout[]) {
    state.allHandout = data
  },
}

export const actions: ActionTree<CompanyState, RootState> = {
  setStaffInfo(ctx, data: AppStaff) {
    ctx.commit('setStaffInfo', data)
  },
  setCompany(ctx, data: AppCompany) {
    ctx.commit('setCompany', data)
  },
  setAllCategory(ctx, data: AppCategory[]) {
    ctx.commit('setAllCategory', data)
  },
  setAllStaff(ctx, data: AppStaff[]) {
    ctx.commit('setAllStaff', data)
  },
  setAllState(ctx, data: AppState[]) {
    ctx.commit('setAllState', data)
  },
  setAllPeriod(ctx, data: AppPeriod[]) {
    ctx.commit('setAllPeriod', data)
  },
  setAllHandout(ctx, data: AppHandout[]) {
    ctx.commit('setAllHandout', data)
  },
}

export const getters: GetterTree<CompanyState, RootState> = {
  staffInfo(state) {
    return state.staffInfo
  },
  company(state) {
    return state.company
  },
  allCategory(state) {
    return state.allCategory
  },
  allStaff(state) {
    return state.allStaff
  },
  allState(state) {
    return state.allState
  },
  allPeriod(state) {
    return state.allPeriod
  },
  allHandout(state) {
    return state.allHandout
  },
  isAdminOrManager(state): boolean {
    if (!state.staffInfo) return false
    return (
      state.staffInfo.role === RoleType.companyAdmin ||
      state.staffInfo.role === RoleType.manager
    )
  }
}
