import { db, storage } from '~/plugins/firebase'

export class HandoutStorage {

  async getHandoutPhotoUrl(photoPath: string) {
    const storageRef = storage.ref(photoPath + "_100x100")
    const handoutPhoto = await storageRef.getDownloadURL()
    return handoutPhoto
  }

  async uploadHandoutPhoto(photo: File): Promise<string> {
    const uuid = db.collection('b').doc().id
    const handoutPhotoPath = `handouts/${uuid}`
    const metaData = {
      name: uuid,
      contentType: 'image/jpeg',
    }
    const storageRef = storage.ref(handoutPhotoPath)
    await storageRef.put(photo, metaData)

    /**
     * リサイズ後の画像が保存されるまで待機
     */
    while (true) {
      try {
        await this.getHandoutPhotoUrl(handoutPhotoPath)
        break
      } catch {
        // wait and retry
        await new Promise(resolve => setTimeout(resolve, 2000))
      }
    }

    console.log('uploaded Handout image')
    return handoutPhotoPath
  }

}
