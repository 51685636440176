// @ts-ignore
import { Observable } from 'rxjs'

import {
  ActionEntity,
  AppAction,
  IActionEntity,
} from '~/entities/actions/actionEntity'

import { HandoutEntity } from '~/entities/companies/handouts/handoutEntity'
import { ActionStorage } from '~/storage/action/actionStorage'

// Formで変更可能なActionデータ
export type FormAction = Pick<
  IActionEntity,
  | 'title'
  | 'content'
  | 'photoPaths'
  | 'salesAmount'
  | 'staffId'
  | 'allHandoutId'
  | 'updatedAt'
  | 'createdAt'
> & {
  dateTimes: string // Formではstringで扱う
}

export class ActionUseCase {
  protected readonly actionEntity: ActionEntity
  protected readonly handoutEntity: HandoutEntity
  protected readonly actionStorage: ActionStorage

  constructor(
    actionEntity: ActionEntity,
    handoutEntity: HandoutEntity,
    actionStorage: ActionStorage
  ) {
    this.actionEntity = actionEntity
    this.handoutEntity = handoutEntity
    this.actionStorage = actionStorage
  }

  getAction$(companyId: string, actionId: string): Observable<AppAction> {
    const action$ = this.actionEntity.getAction$(companyId, actionId)
    return action$
  }

  async getAction(companyId: string, actionId: string): Promise<AppAction> {
    const action = this.actionEntity.getAction(companyId, actionId)
    return action
  }

  getAllCompanyAction$(companyId: string): Observable<AppAction[]> {
    const allCompanyAction = this.actionEntity.getAllCompanyAction$(companyId)
    return allCompanyAction
  }

  getAllClientAction$(
    companyId: string,
    clientId: string
  ): Observable<AppAction[]> {
    const allClientAction = this.actionEntity.getAllClientAction$(
      companyId,
      clientId
    )
    return allClientAction
  }

  async addAction(companyId: string, data: IActionEntity) {
    await this.actionEntity.addAction(companyId, data)
  }

  async updateAction(companyId: string, actionId: string, data: IActionEntity) {
    await this.actionEntity.updateAction(companyId, actionId, data)
  }

  async addActionPhoto(companyId: string, actionId: string, photoPath: string) {
    await this.actionEntity.addActionPhoto(companyId, actionId, photoPath)
  }

  async deleteAction(action: AppAction) {
    /**
     * Entityの削除、Photoの削除
     */
    const deleteHandles = [
      this.actionEntity.deleteAction(action.companyId, action.id),
    ]
    if (action.photoPaths) {
      action.photoPaths.forEach(photoPath => {
        deleteHandles.push(
          this.actionStorage.deleteActionPhoto(photoPath)
        )
      })
    }
    await Promise.all(deleteHandles)
  }

  async deleteAllClientAction(companyId: string, clientId: string) {
    /**
     * FirestoreからallClientActionを取ってくる:
     *   ストアのallCompanyActionをフィルターするのは重くなると思われるため
     */
    const allClientAction = await this.actionEntity.getAllClientAction(companyId, clientId)
    await Promise.all(
      allClientAction.map(action => this.deleteAction(action))
    )
  }

  getActionPhotoUrl(photoPath: string) {
    return this.actionStorage.getActionPhotoUrl(photoPath)
  }

  uploadActionPhoto(photo: File) {
    return this.actionStorage.uploadActionPhoto(photo)
  }
}
