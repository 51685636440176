import { Observable } from 'rxjs'
import {
  StateEntity,
  AppState,
  IStateEntity,
} from '~/entities/companies/state/stateEntity'

export class StateUseCase {
  static readonly COMPANIES = 'companies'
  static readonly STATES = 'states'

  protected readonly stateEntity: StateEntity

  constructor(stateEntity: StateEntity) {
    this.stateEntity = stateEntity
  }

  async addState(
    companyId: string,
    data: IStateEntity
  ): Promise<void> {
    return this.stateEntity.addState(companyId, data)
  }

  getState$(companyId: string, stateId: string): Observable<AppState> {
    return this.stateEntity.getState$(companyId, stateId)
  }

  getAllState$(companyId: string): Observable<AppState[]> {
    return this.stateEntity.getAllState$(companyId)
  }

  getAllUndeletedState$(companyId: string) {
    return this.stateEntity.getAllUndeletedState$(companyId)
  }

  async updateState(
    companyId: string,
    stateId: string,
    data: Partial<IStateEntity>
  ): Promise<void> {
    return this.stateEntity.updateState(companyId, stateId, data)
  }

  deleteState(companyId: string, stateId: string) {
    return this.stateEntity.deleteState(companyId, stateId)
  }
}
