import { db } from '~/plugins/firebase'
import { collection, doc } from 'rxfire/firestore'
import { firstValueFrom, map, Observable } from 'rxjs'
import firebase from 'firebase/app'
import { IUserEntity } from '../../users/userEntity'

export const RoleType = { companyAdmin: 'companyAdmin', manager: 'manager', staff: 'staff' } as const
export type RoleType = typeof RoleType[keyof typeof RoleType]
export const allRoleType = Object.values(RoleType);
// 'staff' | 'companyAdmin'

export type FormAppStaff = Pick<
  AppStaff,
  | 'name'
  | 'kana'
  | 'address'
  | 'emails'
  | 'role'
  | 'note'
  | 'deleted'
  | 'phone'
  | 'photoPath'
  | 'birthDay'
  | 'latitude'
  | 'longitude'
  | 'updatedAt'
  | 'createdAt'
>

export interface IStaffEntity extends Pick<IUserEntity, 'email' | 'companyId'> {
  name: string
  kana?: string
  role: RoleType
  address?: string
  note?: string
  emails?: string[]
  phone?: string
  photoPath?: string
  birthDay?: string
  latitude?: number
  longitude?: number
  deleted: boolean
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface AppStaff extends IStaffEntity {
  id: string
}

export class StaffEntity {
  static readonly STAFF = 'staff'
  static readonly COMPANIES = 'companies'

  private getRef(
    companyId: string,
    staffId: string
  ): firebase.firestore.DocumentReference {
    return this.getCollection(companyId).doc(staffId)
  }

  getCollection(companyId: string): firebase.firestore.CollectionReference {
    const companyRef = db.collection(StaffEntity.COMPANIES).doc(companyId)
    return companyRef.collection(StaffEntity.STAFF)
  }

  getStaff$(companyId: string, staffId: string): Observable<AppStaff> {
    const doc$ = doc(this.getRef(companyId, staffId))

    return doc$.pipe(
      map((snapshot) => {
        const staff = snapshot.data() as IStaffEntity
        const appStaff: AppStaff = { ...staff, id: snapshot.id }
        return appStaff
      })
    )
  }

  getStaff(companyId: string, staffId: string): Promise<AppStaff> {
    return firstValueFrom(this.getStaff$(companyId, staffId))
  }

  async addStaff(companyId: string, userId: string, data: IStaffEntity): Promise<void> {
    return await this.getRef(companyId, userId)
      .set(data)
      .then((_) => {
        console.log('Success addStaff')
      })
  }

  addStaffByBatch(companyId: string, userId: string, data: IStaffEntity, batch: firebase.firestore.WriteBatch): firebase.firestore.WriteBatch {
    return batch.set(
      this.getRef(companyId, userId),
      data
    )
  }

  updateStaff(companyId: string, staffId: string, data: IStaffEntity) {
    return this.getRef(companyId, staffId).update(data)
  }

  //userは他のcompanyのstaffともなりうる usersからは削除しない
  deleteStaff(companyId: string, staffId: string) {
    return this.getRef(companyId, staffId).delete()
  }

  getAllStaff$(companyId: string): Observable<AppStaff[]> {
    //Rxfire のcollection にreferenceを渡す
    const collectionSnapshot$ = collection(this.getCollection(companyId))

    return collectionSnapshot$.pipe(
      map((allSnapshot) => {
        const allStaff: AppStaff[] = []
        allSnapshot.forEach((snapshot) => {
          const staff = snapshot.data() as IStaffEntity
          const appStaff: AppStaff = {
            ...staff,
            id: snapshot.id,
          }
          allStaff.push(appStaff)
        })
        return allStaff
      })
    )
  }
}
