import { Loader } from '@googlemaps/js-api-loader'
import { Plugin } from '@nuxt/types'

const apiKey = process.env.GOOGLE_MAP_API_KEY
if (!apiKey) throw new Error('環境変数GOOGLE_MAP_API_KEYが設定されていません')

export const mapLoader = new Loader({
  apiKey,
  version: 'weekly', // ...additionalOptions,
})

async function geocodeAddress(address: string) {
  await mapLoader.load()
  const geocoder = new google.maps.Geocoder()

  return geocoder.geocode({ address })
}

export async function getLatLngByAddress(
  address: string
): Promise<google.maps.LatLngLiteral> {
  const res = await geocodeAddress(address)

  /**
   * resultが複数でも、results[0]を返す
   * https://developers.google.com/maps/documentation/javascript/geocoding?hl=en#try-sample
   */
  const lat = res.results[0].geometry.location.lat()
  const lng = res.results[0].geometry.location.lng()
  return { lat: lat, lng: lng }
}

export const defaultPositionLatLng = {
  lat: 35.710088789421725,
  lng: 139.81100080859207,
}

export interface MapHelper {
  findCurrentPositionLatLng: () => Promise<google.maps.LatLngLiteral | null>
}

declare module 'vue/types/vue' {
  interface Vue {
    $mapHelper: MapHelper
  }
}
declare module '@nuxt/types' {
  interface Context {
    $mapHelper: MapHelper
  }
}

const alreadyRecommendedFlagKey = 'already recommended to provide current location?'

const plugin: Plugin = (ctx, inject) => {
  function findCurrentPositionLatLng(): Promise<google.maps.LatLngLiteral | null> {
    // 最初の一回のみ、現在地の取得を許可することをユーザーに勧める
    const alreadyRecommended = localStorage.getItem(alreadyRecommendedFlagKey)
    if (!alreadyRecommended) {
      localStorage.setItem(alreadyRecommendedFlagKey, "true")
      alert(ctx.i18n.t('recommendToProvideCurrentLocation').toString())
    }

    return new Promise<google.maps.LatLngLiteral | null>(resolve => {
      const success = (position: GeolocationPosition) => {
        const currentPositionLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        console.log('現在地取得', currentPositionLatLng)
        resolve(currentPositionLatLng)
      }
      const fail = (error: GeolocationPositionError) => {
        console.error('現在地が取得できませんでした。', error.message)
        resolve(null)
      }
      navigator.geolocation.getCurrentPosition(success, fail)
    })
  }

  const mapHelper: MapHelper = {
    findCurrentPositionLatLng
  }
  inject('mapHelper', mapHelper)
}
export default plugin