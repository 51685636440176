import { db, storage } from '~/plugins/firebase'

export class ClientStorage {
  async getClientPhotoUrl(photoPath: string): Promise<string> {
    //'clients/ZAhpkpWwkuEBhPzN4gmK/ clients/ZAhpkpWwkuEBhPzN4gmK      100x100.png'

    const storageRef = storage.ref(photoPath + '_100x100')
    const clientPhoto = await storageRef.getDownloadURL()
    return clientPhoto
  }

  /**
   * @return clientPhotoPath
   */
  async uploadClientPhoto(photo: File): Promise<string> {
    const uuid = db.collection('a').doc().id
    const clientPhotoPath = `clients/${uuid}`
    const metadata = {
      name: uuid,
      contentType: 'image/jpeg',
    }
    const storageRef = storage.ref(clientPhotoPath)
    await storageRef.put(photo, metadata)

    /**
     * リサイズ後の画像が保存されるまで待機
     */
    while (true) {
      try {
        await this.getClientPhotoUrl(clientPhotoPath)
        break
      } catch (error: any) {
        // wait and retry
        console.log(error)
        await new Promise((resolve) => setTimeout(resolve, 2000))
      }
    }

    console.log('uploaded Client image')
    return clientPhotoPath
  }

  async deleteClientPhoto(photoPath: string) {
    const deletedPhotoPath = photoPath + '_100x100'
    await storage.ref(deletedPhotoPath).delete() 
  }
}
