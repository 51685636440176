import { db, storage } from '~/plugins/firebase'

export class ActionStorage {
  async getActionPhotoUrl(photoPath: string): Promise<string> {
    //'actions/ZAhpkpWwkuEBhPzN4gmK/ actions/ZAhpkpWwkuEBhPzN4gmK      100x100.png'

    const storageRef = storage.ref(photoPath + '_200x200')
    const actionPhoto = await storageRef.getDownloadURL()
    return actionPhoto
  }

  /**
   * @return actionPhotoPath
   */
  async uploadActionPhoto(photo: File): Promise<string> {
    const uuid = db.collection('a').doc().id
    const actionPhotoPath = `actions/${uuid}`
    const metadata = {
      name: uuid,
      contentType: 'image/jpeg',
    }
    const storageRef = storage.ref(actionPhotoPath)
    await storageRef.put(photo, metadata)

    /**
     * リサイズ後の画像が保存されるまで待機
     */
    while (true) {
      try {
        await this.getActionPhotoUrl(actionPhotoPath)
        break
      } catch (error: any) {
        // wait and retry
        console.log('保存されるまで待機. ', error)
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    }

    console.log('uploaded Action image')
    return actionPhotoPath
  }

  async deleteActionPhoto(photoPath: string) {
    const deletedPhotoPaths = [
      photoPath + '_100x100',
      photoPath + '_200x200',
    ]
    storage.ref('')
    await Promise.all(
      deletedPhotoPaths.map(path => storage.ref(path).delete())
    )
  }
}
