import { Observable } from 'rxjs'
import firebase from 'firebase/app'
import { auth, firestoreTimestamp, db } from '~/plugins/firebase'
import {
  IStaffInvitationEntity,
  AppStaffInvitation,
  StaffInvitationEntity,
} from '~/entities/companies/staffInvitations/staffInvitationEntity'
import { queryFn } from '~/entities'
import { IStaffEntity, RoleType } from '~/entities/companies/staff/staffEntity'

export const staffInvitationCodeQueryName = 'inviteCode'
export function staffInvitationActionCodeSettings(inviteCode: string) {
  return {
    url: `${process.env.BASE_URL}staffJoin/?${staffInvitationCodeQueryName}=${inviteCode}`,
    handleCodeInApp: true,
  }
}

export class StaffInvitationUseCase {
  protected readonly staffInvitationEntity: StaffInvitationEntity

  constructor(staffInvitationEntity: StaffInvitationEntity) {
    this.staffInvitationEntity = staffInvitationEntity
  }

  getRef(
    companyId: string,
    staffInvitationId: string
  ): firebase.firestore.DocumentReference {
    return this.staffInvitationEntity.getRef(companyId, staffInvitationId)
  }

  getCollection(companyId: string): firebase.firestore.CollectionReference {
    return this.staffInvitationEntity.getCollection(companyId)
  }

  getQuery(companyId: string, queryFn?: queryFn): firebase.firestore.Query {
    return this.staffInvitationEntity.getQuery(companyId, queryFn)
  }

  getStaffInvitations(
    companyId: string,
    queryFn?: queryFn
  ): Observable<AppStaffInvitation[]> {
    return this.staffInvitationEntity.getStaffInvitations(companyId, queryFn)
  }

  async addStaffInvitation(
    companyId: string,
    data: IStaffInvitationEntity,
    { skipValid }: { skipValid?: boolean } = {}
  ): Promise<firebase.firestore.DocumentReference> {
    return await this.staffInvitationEntity.addStaffInvitation(companyId, data, {
      skipValid,
    })
  }


  approveByBatch(
    companyId: string,
    staffInvitationId: string,
    batch: firebase.firestore.WriteBatch
  ): firebase.firestore.WriteBatch {
    return this.staffInvitationEntity.approveByBatch(
      companyId,
      staffInvitationId,
      batch
    )
  }

 
  /**
   * @raise validate失敗時エラーを返す
   */
  async validate(companyId: string, staffInvitation: Partial<IStaffInvitationEntity>) {
    this.staffInvitationEntity.validate(companyId, staffInvitation)
  }

  async isEmailAlreadyinvited(
    companyId: string,
    email: string
  ): Promise<boolean> {
    return this.staffInvitationEntity.isEmailAlreadyInvited(companyId, email)
  }

  /**
   * - staffInvitationEntity作成
   * - 招待メール送信
   */
  async sendStaffInvitationEmail(
    companyId: string,
    staffInvitationEntity: IStaffInvitationEntity
  ) {
    /**
     * サーバー側のバリデーション
     * Email送信の前に、staffInvitationのバリデーションのみを行う
     */
    await this.staffInvitationEntity.validate(companyId, staffInvitationEntity)

    await auth.sendSignInLinkToEmail(
      staffInvitationEntity.email,
      staffInvitationActionCodeSettings(staffInvitationEntity.inviteCode)
    )
    await this.staffInvitationEntity.addStaffInvitation(companyId, staffInvitationEntity, {
      skipValid: true,
    })
  }

  findUnapprovedStaffInvitationByInviteCode$(inviteCode: string) {
    return this.staffInvitationEntity.findUnapprovedStaffInvitationByInviteCode$(inviteCode)
  }

  // ??TODO: Check staffjoin のページからstaff を招待情報に基づきsetするため不要
  // async joinCompany(companyId: string, staffInvitationId: string) {
  //   const batch = db.batch()
  //   const tempDoc = await this.staffInvitationEntity
  //     .getRef(companyId, staffInvitationId)
  //     .get()

  //   const staffInvitation = tempDoc.data() as IStaffInvitationEntity
  //   const newStaffData = this.makeStaffData(companyId, staffInvitation)
  //   // batch処理
  //   this.staffInvitationEntity.updateStaffInvitationByBatch(
  //     companyId,
  //     staffInvitationId,
  //     newStaffData
  //   )
  //   return batch.commit()
  // }

  makeStaffData(companyId: string, staffInvitation: IStaffInvitationEntity): IStaffEntity {
    const newStaffData: IStaffEntity = {
      emails: [staffInvitation.email],
      name: staffInvitation.staffName,
      role: RoleType.staff,
      address: '',
      deleted: false,
      email: staffInvitation.email,
      companyId: companyId,
      createdAt: firestoreTimestamp(),
      updatedAt: firestoreTimestamp()
    }
    return newStaffData
  }
}
