import { collection } from 'rxfire/firestore'
import firebase from 'firebase/app'
import { db } from '~/plugins/firebase'
import { map, Observable } from 'rxjs'

import { queryFn } from '~/entities'
import { CompanyEntity } from '../companyEntity'
import { AppStaff, IStaffEntity } from '~/entities/companies/staff/staffEntity'

export interface IStaffInvitationEntity {
  staffName: string
  email: string
  inviterName: string
  companyId: string
  approved: boolean // 承認済みかどうか
  inviteCode: string // メールのリンクに貼る識別コード
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export interface StaffInvitationFormData
  extends Pick<IStaffInvitationEntity, 'staffName' | 'email'> {}

// 現場共有staff情報 emailが送付された後生成される
// Google authの前
// Google loginが成功すると IStaffTypeをauthenticatedに変更する

// staffName: emailだけでは識別しにくいので名前を入力してもらう
// staffType: 認証のstatus
// email:　送付するGmail Gmail以外は送付できないvalidationを追加する
// companyId: どのフィールドか確認するため
// deleted: staffが削除された場合 trueにする
// createdAt: firebase.firestore.Timestamp
// updatedAt: firebase.firestore.Timestamp

export interface AppStaffInvitation extends IStaffInvitationEntity {
  id: string
}
const duplicatedEmailErrorMsg = '既に招待を送っています。'

export class StaffInvitationEntity {
  static readonly TEMP_STAFFS = 'staff-invitations'
  static readonly COMPANIES = 'companies'

  constructor() {}

  getRef(
    companyId: string,
    staffInvitationId: string
  ): firebase.firestore.DocumentReference {
    return this.getCollection(companyId).doc(staffInvitationId)
  }

  getCollection(companyId: string): firebase.firestore.CollectionReference {
    return db
      .collection(CompanyEntity.COMPANIES)
      .doc(companyId)
      .collection(StaffInvitationEntity.TEMP_STAFFS)
  }

  getQuery(companyId: string, queryFn?: queryFn): firebase.firestore.Query {
    const collection = this.getCollection(companyId)
    return queryFn ? queryFn(collection) : collection
  }

  /**
   * @returns null: StaffInvitationが見つからなかったとき
   */
  findUnapprovedStaffInvitationByInviteCode$(
    inviteCode: string
  ): Observable<AppStaffInvitation[]> {
    const collectionSnapshot$ = collection(
      db
        .collectionGroup(StaffInvitationEntity.TEMP_STAFFS)
        .where('inviteCode', '==', inviteCode)
        .where('approved', '==', false)
    )
    return collectionSnapshot$.pipe(
      map((collectionSnapshot) => {
        return collectionSnapshot.map(staffSnapshot => {
          const staffEntity = staffSnapshot.data() as IStaffInvitationEntity
          const staff: AppStaffInvitation = {
            ...staffEntity,
            id: staffSnapshot.id,
          }
          return staff
        })
      })
    )
  }

  getStaffInvitations(
    companyId: string,
    queryFn?: queryFn
  ): Observable<AppStaffInvitation[]> {
    return collection(this.getQuery(companyId, queryFn)).pipe(
      map((docs) => {
        return docs.map((snapshot) => {
          const entity = snapshot.data() as IStaffInvitationEntity

          // Entity -> AppModel 変換
          return {
            ...entity,
            id: snapshot.id,
          }
        })
      })
    )
  }

  async addStaffInvitation(
    companyId: string,
    data: IStaffInvitationEntity,
    { skipValid }: { skipValid?: boolean } = {}
  ): Promise<firebase.firestore.DocumentReference> {
    // skipValidを実装しているのは、TempUseCaseで使用するため
    if (!skipValid) {
      await this.validate(companyId, data)
    }
    return this.getCollection(companyId).add(data)
  }

  approveByBatch(companyId: string, staffInvitationId: string, batch: firebase.firestore.WriteBatch):
    firebase.firestore.WriteBatch
  {
    const updateData: Pick<IStaffInvitationEntity, 'approved'> = {
      approved: true,
    }
    return batch.update(this.getRef(companyId, staffInvitationId), updateData)
  }

  /**
   * @raise validate失敗時エラーを返す
   */
  async validate(
    companyId: string,
    staffInvitation: Partial<IStaffInvitationEntity>
  ) {
    if (staffInvitation.email) {
      // 既に招待を送ったEmailへの招待はできない
      const isEmailAlreadyInvited = await this.isEmailAlreadyInvited(
        companyId,
        staffInvitation.email
      )
      if (isEmailAlreadyInvited) throw new Error(duplicatedEmailErrorMsg)
    }
  }

  async isEmailAlreadyInvited(
    companyId: string,
    email: string
  ): Promise<boolean> {
    const snapshot = await this.getCollection(companyId)
      .where('email', '==', email)
      .where('approved', '==', false)
      .get()

    return snapshot.empty ? false : true
  }
}
