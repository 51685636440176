
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import FeedbackForm from '~/components/view/layouts/feedbackForm.vue'
import { AppCompany } from '~/entities/companies/companyEntity'
import { AppStaff } from '~/entities/companies/staff/staffEntity'
import { AppUser } from '~/entities/users/userEntity'

@Component({
  components: {
    FeedbackForm,
  },
  computed: {
    ...mapGetters('layout', ['pageTitle']),
    ...mapGetters('company', ['company', 'staffInfo']),
    ...mapGetters('auth', ['userInfo']),
  },
  i18n: {
    messages: {
      ja: {
        feedback: 'ご意見・ご要望',
        language: '言語',
      },
      en: {
        feedback: 'Feedback',
        language: 'Language',
      },
    },
  },
})
export default class TopNavComponent extends Vue {
  userInfo!: AppUser | null
  staffInfo!: AppStaff | null
  company!: AppCompany | null
  pageTitle!: string | null

  isFeedbackDialogOpen = false
  get availableLocales() {
    // I18n参考: https://i18n.nuxtjs.org/lang-switcher/
    return this.$i18n.locales
  }

  isLoginPage() {
    return this.$route.path === '/login/'
  }
}
