import { db, storage } from '~/plugins/firebase'

export class CompanyStorage {
  async getCompanyPhotoUrl(photoPath: string): Promise<string> {
    //'companies/ZAhpkpWwkuEBhPzN4gmK/ companies/ZAhpkpWwkuEBhPzN4gmK      100x100.png'
    const storageRef = storage.ref(photoPath + "_100x100")
    const companyPhoto = await storageRef.getDownloadURL()
    return companyPhoto
  }

  /**
   * @return companyPhotoPath
   */
  async uploadCompanyPhoto(photo: File): Promise<string> {
    const uuid = db.collection('a').doc().id
    const companyPhotoPath = `companies/${uuid}`
    const metadata = {
      name: uuid,
      contentType: 'image/jpeg',
    }
    const storageRef = storage.ref(companyPhotoPath)
    await storageRef.put(photo, metadata)

    /**
     * リサイズ後の画像が保存されるまで待機
     */
    while (true) {
      try {
        await this.getCompanyPhotoUrl(companyPhotoPath)
        break
      } catch {
        // wait and retry
        console.log('wait resize')
        await new Promise(resolve => setTimeout(resolve, 2000))
      }
    }

    console.log('uploaded Company image')
    return companyPhotoPath
  }
}
