
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {},
  computed: {},
})
export default class AccountPhotoComponent extends Vue {
  @Prop({ required: true })
  logoPhotoPath!: string | undefined
  logoPhotoUrl: string | null = null

  @Watch('photoPath', { immediate: true })
  async watchPhotoPath() {
    if (this.logoPhotoPath) {
      this.logoPhotoUrl = await this.$deps.companyUseCase.getCompanyPhotoUrl(
        this.logoPhotoPath
      )
    }
  }
}
