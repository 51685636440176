import { Plugin } from "@nuxt/types";

const plugin: Plugin = (ctx) => {
  window.addEventListener('error', (event) => {
    ctx.error(event.error)
  })
  window.addEventListener('unhandledrejection', (event) => {
    ctx.error(event.reason)
  })
}
export default plugin