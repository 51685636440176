import { Middleware } from "@nuxt/types";

/**
 * ClientShow配下のページで, 
 * route.paramsからclientIdを取得しStoreに保存する
 * 
 * Storeのgetter内でrouteにアクセスできないため必要になる
 */
const middleware: Middleware = (ctx) => {
  if (!ctx.route.name) throw new Error('context.route.nameが設定されていません')

  let currentClientId: string | null = null
  if (ctx.route.name.startsWith('clients-clientId')) {
    // clients-show配下のページ
    currentClientId = ctx.route.params['clientId']
    if (!currentClientId) throw new Error('route.paramsにclientIdがありません。')
  } else {
    // clients-show配下ではないページではNull
    currentClientId = null
  }

  ctx.store.commit('client/setCurrentClientId', currentClientId)
}
export default middleware
