import { FeedbackEntity, IFeedbackEntity } from '~/entities/feedbacks/feedbackEntity'
import { FeedbackStorage } from '~/storage/feedback/feedbackStorage'

export class FeedbackUseCase {
  static readonly HANDOUTS = 'feedbacks'

  protected readonly feedbackEntity: FeedbackEntity
  protected readonly feedbackStorage: FeedbackStorage

  constructor(feedbackEntity: FeedbackEntity, feedbackStorage: FeedbackStorage) {
    this.feedbackEntity = feedbackEntity
    this.feedbackStorage = feedbackStorage
  }

  async addFeedback(data: IFeedbackEntity) {
    return await this.feedbackEntity.addFeedback(data)
  }

  getFeedbackPhotoUrl(feedbackPath: string) {
    return this.feedbackStorage.getFeedbackPhotoUrl(feedbackPath)
  }

  async uploadFeedbackPhoto(photo: File): Promise<string> {
    return this.feedbackStorage.uploadFeedbackPhoto(photo)
  }
}
