// 型付け参考 https://typescript.nuxtjs.org/ja/cookbook/store#vanilla

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { AppAction } from '~/entities/actions/actionEntity'
import { AppClient } from '~/entities/clients/clientEntity'
import { RootState } from '~/store'

export interface ActionState {
  allCompanyAction: AppAction[] | null
  allClientAction: AppAction[] | null
}

export const state: any = () => ({
  allCompanyAction: null,
  allClientAction: null,
})

export const mutations: MutationTree<ActionState> = {
  setAllCompanyAction(state, data: AppAction[]) {
    state.allCompanyAction = data
  },
  setAllClientAction(state, data: AppAction[]) {
    state.allClientAction = data
  }
}

export const actions: ActionTree<ActionState, RootState> = {
  setAllCompanyAction(ctx, data: AppAction[]){
    ctx.commit('setAllCompanyAction', data)
  },
  setAllClientAction(ctx, data:{ clientId: string, allAction: AppAction[]}){
    const clientAllAction = data.allAction.filter((action) => action.clientId === data.clientId)
    ctx.commit('setAllClientAction', clientAllAction)
  }
}

export const getters: GetterTree<ActionState, RootState> = {
  allCompanyAction(state: ActionState){
    return  state.allCompanyAction
  },
  allClientAction(state: ActionState) {
    return state.allClientAction
  }
}
