// 型付け参考 https://typescript.nuxtjs.org/ja/cookbook/store#vanilla

import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'

export interface LayoutState {
  pageTitle: string | null
}

export const state: any = () => ({
  pageTitle: null
})

export const mutations: MutationTree<LayoutState> = {
  setPageTitle(state, data: string) {
    state.pageTitle = data
  },
}

export const actions: ActionTree<LayoutState, RootState> = {
  setPageTitle(ctx, data: string) {
    ctx.commit('setPageTitle', data)
  },
}

export const getters: GetterTree<LayoutState, RootState> = {
  pageTitle(state) {
    return state.pageTitle
  }
}
