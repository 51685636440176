import firebase from 'firebase'
import { map, Observable } from 'rxjs'
import { AppClient, ClientEntity, IClientEntity } from '~/entities/clients/clientEntity'
import { ClientStorage } from '~/storage/client/clientStorage'

export type FormClient = Pick<
  IClientEntity,
  | 'categoryId'
  | 'staffId'
  | 'stateId'
  | 'name'
  | 'kana'
  | 'companyName'
  | 'address'
  | 'latitude'
  | 'longitude'
  | 'emails'
  | 'phone'
  | 'photoPath'
  | 'birthday'
  | 'age'
  | 'social'
  | 'note'
  | 'createdAt'
  | 'updatedAt'
>

export class ClientUseCase {
  protected readonly clientEntity: ClientEntity
  protected readonly clientStorage: ClientStorage

  constructor(clientEntity: ClientEntity, clientStorage: ClientStorage) {
    this.clientEntity = clientEntity
    this.clientStorage = clientStorage
  }

  getClient$(companyId: string, clientId: string): Observable<AppClient> {
    return this.clientEntity.getClient$(companyId, clientId)
  }

  async getClient(companyId: string, clientId: string): Promise<AppClient> {
    return await this.clientEntity.getClient(companyId, clientId)
  }

  getAllClient$(companyId: string): Observable<AppClient[]> {
    return this.clientEntity.getAllClient$(companyId)
  }

  //formからsubmitで呼ばれる
  addClient(companyId: string, data: FormClient) {
    return this.clientEntity.addClient(companyId, data)
  }

  updateClient(companyId: string, clientId: string, data: FormClient) {
    return this.clientEntity.updateClient(companyId, clientId, data)
  }

  // ActionFormで使用
  updateClientState(companyId: string, clientId: string, data: Pick<FormClient, "stateId">) {
    return this.clientEntity.updateClient(companyId, clientId, data)
  }

  getClientPhotoUrl(photoPath: string) {
    return this.clientStorage.getClientPhotoUrl(photoPath)
  }

  uploadClientPhoto(photo: File) {
    return this.clientStorage.uploadClientPhoto(photo)
  }

  async deleteClient(client: AppClient) {
    /**
     * Entityの削除、Photoの削除
     */
    const deleteHandles = [
      this.clientEntity.deleteClient(client.companyId, client.id),
    ]
    if (client.photoPath) {
      deleteHandles.push(
        this.clientStorage.deleteClientPhoto(client.photoPath)
      )
    }
    await Promise.all(deleteHandles)
  }
}
